<template>
  <div class="uk-padding-small">
    <div class="uk-grid-medium uk-margin-bottom" data-uk-grid data-uk-height-match>
      
      <div class="uk-width-4-5@xl uk-width-3-4@l">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>Contribution To Comp</h4></div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <contribution-to-comp-channel></contribution-to-comp-channel>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-1-5@xl uk-width-1-4@l">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>Filters</h4></div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <channel-filter-loader></channel-filter-loader>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-1-1@xl">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>Channel Comparison</h4></div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <channel-bar-chart></channel-bar-chart>
            </div>
          </div>
        </div>
      </div>
      
      <div class="uk-width-1-1@xl">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>Channel Mix</h4></div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <channel-mix></channel-mix>
            </div>
          </div>
        </div>
      </div>
      

    </div>
  </div>
</template>

<script>
import ContributionToCompChannel from "./Components/ContributionToCompChannel";
import ChannelBarChart from "./Components/ChannelBarChart";
import ChannelChart from "./Components/ChannelChart";
import ChannelFilterLoader from "./Components/ChannelFilterLoader";
import ChannelMix from "./Components/ChannelMix";

export default {
  name: "ChannelDetailApp",
  components: {
    ContributionToCompChannel,
    ChannelBarChart,
    ChannelChart,
    ChannelFilterLoader,
    ChannelMix
  },
  methods: {
    backToOverview () {
      this.eventHub.$emit("backToOverview", true);
    },
  },

}
</script>

<style scoped>

</style>
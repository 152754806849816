<template>
  <DxDataGrid
      :show-borders="true"
      :data-source="dataSource"
      @exporting="onExporting"
      height="360">

    <DxExport
        :enabled="true"
    />
    <DxFilterRow :visible="true" />

    <DxHeaderFilter :visible="true" />

    <DxScrolling mode="infinite"/>

    <DxColumn
        data-field="groupName"
        caption="Store Name" />

    <DxColumn
        data-field="pctChangeLunch"
        caption="Lunch"
        cell-template="Lunch"/>

    <DxColumn
        data-field="pctChangeMidday"
        caption="Midday"
        cell-template="Midday"/>

    <DxColumn
        data-field="pctChangeDinner"
        caption="Dinner"
        cell-template="Dinner"/>

    <DxColumn
        data-field="pctChangeLateNight"
        caption="Late Night"
        cell-template="LateNight"/>

    <DxColumn
        data-field="pctChangeTotal"
        caption="Total"
        cell-template="Total"/>

    <template #Lunch="{ data }">
      <div class="uk-flex uk-flex-column">
        <span class="top-metric">{{ data.value | formatPercentage }}</span>
        <span v-if="data.data.changeLunch < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{
            data.data.changeLunch | formatMoney
          }} </span>
        <span v-if="data.data.changeLunch >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{
            data.data.changeLunch | formatMoney
          }} </span>
      </div>
    </template>


    <template #Midday="{ data }">
      <div class="uk-flex uk-flex-column">
        <span class="top-metric">{{ data.value | formatPercentage }}</span>
        <span v-if="data.data.changeMidday < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{
            data.data.changeMidday | formatMoney
          }} </span>
        <span v-if="data.data.changeMidday >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{
            data.data.changeMidday | formatMoney
          }} </span>
      </div>
    </template>

    <template #Dinner="{ data }">
      <div class="uk-flex uk-flex-column">
        <span class="top-metric">{{ data.value | formatPercentage }}</span>
        <span v-if="data.data.changeDinner < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{
            data.data.changeDinner | formatMoney
          }} </span>
        <span v-if="data.data.changeDinner >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{
            data.data.changeDinner | formatMoney
          }} </span>
      </div>
    </template>

    <template #Dinner="{ data }">
      <div class="uk-flex uk-flex-column">
        <span class="top-metric">{{ data.value | formatPercentage }}</span>
        <span v-if="data.data.changeDinner < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{
            data.data.changeDinner | formatMoney
          }} </span>
        <span v-if="data.data.changeDinner >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{
            data.data.changeDinner | formatMoney
          }} </span>
      </div>
    </template>

    <template #Total="{ data }">
      <div class="uk-flex uk-flex-column">
        <span class="top-metric">{{ data.value | formatPercentage }}</span>
        <span v-if="data.data.changeTotal < 0"
              class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeTotal | formatMoney }} </span>
        <span v-if="data.data.changeTotal >= 0"
              class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeTotal | formatMoney }} </span>
      </div>
    </template>

    <template #LateNight="{ data }">
      <div class="uk-flex uk-flex-column">
        <span class="top-metric">{{ data.value | formatPercentage }}</span>
        <span v-if="data.data.changeLateNight < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{
            data.data.changeLateNight | formatMoney
          }} </span>
        <span v-if="data.data.changeLateNight >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{
            data.data.changeLateNight | formatMoney
          }} </span>
      </div>
    </template>
  </DxDataGrid>
</template>

<script>
import {
  DxColumn,
  DxDataGrid,
  DxMasterDetail,
  DxFilterRow,
  DxHeaderFilter,
  DxScrolling,
  DxExport
} from 'devextreme-vue/data-grid';
import {createStore} from "devextreme-aspnet-data-nojquery";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from 'file-saver';

export default {
  name: "YesterdayLevel2",
  components: {
    DxDataGrid,
    DxColumn,
    DxMasterDetail,
    DxFilterRow,
    DxHeaderFilter,
    DxScrolling,
    DxExport
  },

  data() {
    return {
      dataSource: this.loadData(this.templateData.key.groupName),
      dollarFormat: { style: 'currency', currency: 'USD'}
    }
  },

  props: {
    templateData: {
      type: Object,
      default: () => ({}),
    },
    
    isCorporate: Boolean
  },

  mounted() {},

  filters: {
    formatPercentage (number) {
      if (number === null)
        return 'Null';

      number = number.toFixed(1);
      number = number.toString() + "%";

      return number;
    },


    formatMoney (number) {
      if (number == null)
        return 'Unknown';
      return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}).format(number);
    }
  },

  methods: {
    loadData(group) {
      const url = this.getApiUrl();
      const clientId = this.getClientId();
      return createStore({
        loadUrl: `${url}/api/slims-report/franchise/yesterday-detail`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }

          ajaxOptions.data = {
            isCorporate: this.isCorporate,
            groupName: group,
            clientId: clientId
          };
        }
      });
    },

    onExporting(e) {
      let group = this.templateData.key.groupName;

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(group + ' Stores Comp % Yesterday');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet
      }).then(function (dataGridRange) {
        for (let x = 2; x < dataGridRange.to.column + 1; x++) {
          for (let y = 2; y < dataGridRange.to.row + 1; y++) {

            let cell = worksheet.getRow(y).getCell(x)

            Object.assign(
                worksheet.getRow(y).getCell(x),
                {value: cell.value + "%"}
            );
          }
        }
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], {type: "application/octet-stream"}),
              group + ' Stores Comp % Yesterday.xlsx');
        });

      });
    }
  }
}
</script>

<style scoped>

.top-metric {
  font-size: large;
}

</style>
<template>
  <div v-if="!isLoading">
    <DxRangeSelector
        v-model:value="this.range"
        @value-changed="filterDatesFunction"
        @content-ready="filterDatesFunction"
    >
      <DxScale
          :start-value="this.startValue"
          :end-value="this.endValue"
      >
        <DxMinorTick :visible="false"/>
        <DxMinorTickInterval :days="1"/>
        <DxTickInterval :weeks="1"/>
      </DxScale>
      <DxSliderMarker format="monthAndDay"/>
    </DxRangeSelector>
  </div>
</template>

<script>
import {
  DxMargin, DxMaxRange,
  DxMinorTick,
  DxMinorTickInterval, DxMinRange,
  DxRangeSelector,
  DxScale, DxSliderMarker,
  DxTickInterval
} from "devextreme-vue/range-selector";
import axios from "axios";

export default {
  name: "RangeFilter",
  components: {
    DxRangeSelector,
    DxMargin,
    DxScale,
    DxMinorTick,
    DxMinorTickInterval,
    DxTickInterval,
    DxMinRange,
    DxMaxRange,
    DxSliderMarker,

  },

  created() {
    this.eventHub.$on('clearFilter', this.clearSelection)
  },

  beforeDestroy() {
    this.eventHub.$off('clearFilter');
  },

  data() {
    return {
      isLoading: true,
      data: null,
      range: [],
      startValue: null,
      endValue: null
    }
  },

  methods: {
    filterDatesFunction: function (e) {
      if (!e.component._isSliding) {
        let firstSelectedDate = (e.value[0]);
        let lastSelectedDate = (e.value[1]);

        firstSelectedDate = firstSelectedDate.toISOString();
        lastSelectedDate = lastSelectedDate.toISOString();

        const filterArr = [firstSelectedDate, lastSelectedDate];

        this.eventHub.$emit('datesArr', filterArr);
      }
    },

    clearSelection() {
      this.range = [this.startValue, this.endValue];
    },
  },


  mounted() {
    this.isLoading = true;
    const token = this.getTokenSilently();
    const url = this.getApiUrl();
    const clientId = this.getClientId();

    let me = this;
    axios.get(`${url}/api/slims-report/get-date-range`, {
      headers: {Authorization: `Bearer ${token}`},
      params: {
        clientId: clientId
      }
    })
        .then(response => {
          me.data = response.data;
        })
        .catch(err => {
        })
        .finally(() => {

          for (let x = 0; x < me.data.length; x++)
            me.range.push(new Date(me.data[x].businessDate));

          me.endValue = new Date(Math.max.apply(null, me.range));
          me.startValue = new Date(Math.min.apply(null, me.range));

          me.range = [me.startValue, me.endValue];

          let days = 86400000 //number of milliseconds in a day
          let startRange = new Date(me.endValue - (6 * days))

          me.range = [startRange.toISOString(), me.endValue.toISOString()]

          this.eventHub.$emit('datesArr', me.range);

          me.isLoading = false;
        });
  },

}
</script>

<style scoped>

</style>
<template>
  <div v-if="columnData !== null && dataSource !== null">
    <DxDataGrid
        :show-borders="true"
        :data-source="dataSource"
        height="600px"
        @exporting="onExporting">
      
      <DxFilterRow :visible="true"/>

      <DxHeaderFilter :visible="true"/>

      <DxScrolling mode="infinite"/>
      <DxExport
          :enabled="true"
      />
      

      <DxColumn
          data-field="groupName"
          caption="Group Name"/>

      <DxColumn
          v-if="colShow(1)"
          data-field="pctChangeWeek1"
          :caption="colName(1)"
          cell-template="week1"/>

      <DxColumn
          v-if="colShow(2)"
          data-field="pctChangeWeek2"
          :caption="colName(2)"
          cell-template="week2"/>

      <DxColumn
          v-if="colShow(3)"
          data-field="pctChangeWeek3"
          :caption="colName(3)"
          cell-template="week3"/>

      <DxColumn
          v-if="colShow(4)"
          data-field="pctChangeWeek4"
          :caption="colName(4)"
          cell-template="week4"/>

      <DxColumn
          v-if="colShow(5)"
          data-field="pctChangeWeek5"
          :caption="colName(5)"
          cell-template="week5"/>

      <DxColumn
          data-field="pctChangeTotal"
          caption="Total"
          cell-template="Total"/>

      <template #week1="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changeWeek1 < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{
              data.data.changeWeek1 | formatMoney
            }} </span>
          <span v-if="data.data.changeWeek1 >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{
              data.data.changeWeek1 | formatMoney
            }} </span>
        </div>
      </template>

      <template #week2="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changeWeek2 < 0"
                class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeWeek2 | formatMoney }} </span>
          <span v-if="data.data.changeWeek2 >= 0"
                class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeWeek2 | formatMoney }} </span>
        </div>
      </template>

      <template #week3="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changeWeek3 < 0"
                class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeWeek3 | formatMoney }} </span>
          <span v-if="data.data.changeWeek3 >= 0"
                class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeWeek3 | formatMoney }} </span>
        </div>
      </template>

      <template #Total="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changeTotal < 0"
                class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeTotal | formatMoney }} </span>
          <span v-if="data.data.changeTotal >= 0"
                class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeTotal | formatMoney }} </span>
        </div>
      </template>

      <template #week4="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changeWeek4 < 0"
                class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeWeek4 | formatMoney }} </span>
          <span v-if="data.data.changeWeek4 >= 0"
                class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeWeek4 | formatMoney }} </span>
        </div>
      </template>


      <template #week5="{ data }">
        <div class="uk-flex uk-flex-column">
          <span class="top-metric">{{ data.value | formatPercentage }}</span>
          <span v-if="data.data.changeWeek5 < 0"
                class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeWeek5 | formatMoney }} </span>
          <span v-if="data.data.changeWeek5 >= 0"
                class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeWeek5 | formatMoney }} </span>
        </div>
      </template>

      <DxMasterDetail
          :enabled="true"
          template="masterDetailTemplate"
      />

      <template #masterDetailTemplate="{ data }">

              <period-level2 
                  class="div-background"
                  :template-data="data"
                             :is-corporate="isCorporate"
              />

      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxColumn,
  DxDataGrid,
  DxMasterDetail,
  DxFilterRow,
  DxHeaderFilter,
  DxScrolling,
  DxExport
} from 'devextreme-vue/data-grid';
import PeriodLevel2 from "./PeriodLevel2";

import {createStore} from "devextreme-aspnet-data-nojquery";
import axios from "axios";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from 'file-saver';

export default {
  name: "PeriodLevel1",
  components: {
    DxDataGrid,
    DxColumn,
    DxMasterDetail,
    DxFilterRow,
    DxHeaderFilter,
    DxScrolling,
    PeriodLevel2,
    DxExport
  },

  data() {
    return {
      dataSource: null,
      isLoading: false,
      columnData: null,
    }
  },

  mounted() {
    this.loadData();
  },

  props: {
    isCorporate: Boolean,
  },

  filters: {
    formatPercentage(number) {
      if (number === null)
        return 'Null';

      number = number.toFixed(1);
      number = number.toString() + "%";

      return number;
    },


    formatMoney(number) {
      if (number == null)
        return 'Unknown';
      return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}).format(number);
    }
  },

  methods: {

    colName(index) {
      if (this.columnData.length >= index)
        return 'Period ' + this.columnData[index - 1].period + ' (Week ' + this.columnData[index - 1].weekNumber + ')';
      else
        return "";
    },

    colShow(index) {
      if (this.columnData.length >= index)
        return true;
      else
        return false;
    },

    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Franchise Groups Comp % Period');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet
      }).then(function (dataGridRange) {
        for (let x = 2; x < dataGridRange.to.column + 1; x++) {
          for (let y = 2; y < dataGridRange.to.row + 1; y++) {

            let cell = worksheet.getRow(y).getCell(x)

            Object.assign(
                worksheet.getRow(y).getCell(x),
                {value: cell.value + "%"}
            );
          }
        }
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], {type: "application/octet-stream"}),
              "Franchise Groups Comp % Period.xlsx");
        });

      });
    },

    loadData() {

      this.isLoading = true;
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/franchise/period-detail`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }

          ajaxOptions.data = {
            isCorporate: this.isCorporate,
            clientId: clientId
          };
        }
      });

      let me = this;
      const token = this.getTokenSilently();
      axios.get(`${url}/api/slims-report/get-period-information`, {
        headers: {Authorization: `Bearer ${token}`},
        params: {clientId: clientId}
      }).then(response => {
        me.columnData = response.data;
      }).finally(function () {
        me.isLoading = false;
      });
    }
  }
}
</script>

<style scoped>

.top-metric {
  font-size: large;
}

.div-background {
  border: solid 1px black;
}

</style>
<template>
  <div v-if="dataSource !== null" class="dx-field">
    <div class="center uk-align-center uk-margin-remove">
        <DxDropDownBox
            v-model:value="gridBoxValue"
            :show-clear-button="false"
            :data-source="dataSource"
            display-expr="itemName"
            value-expr="itemName"
            placeholder="Select the promotion item to view..."
        >
          <template #content="{ data }">
            <DxDataGrid
                :data-source="dataSource"
                @selection-changed="onGridSelectionChanged($event)"
                height="100%"
            >
              <DxSelection mode="single"/>
              <DxPaging
                  :enabled="true"
                  :page-size="10"
              />
              <DxFilterRow :visible="true"/>
              <DxScrolling mode="virtual"/>
            </DxDataGrid>
          </template>
        </DxDropDownBox>
      </div>
    </div>
</template>
<script>
import DxDropDownBox from 'devextreme-vue/drop-down-box';
import DxTreeView from 'devextreme-vue/tree-view';
import {
  DxDataGrid, DxSelection, DxPaging, DxFilterRow, DxScrolling,
} from 'devextreme-vue/data-grid';
import {createStore} from "devextreme-aspnet-data-nojquery";

// let dataSource = createStore({
//   loadMode: "raw",
//   loadUrl: `/api/slims-report/get-promo-items`,
//   onBeforeSend: (method, ajaxOptions) => {
//     const token = this.getTokenSilently();
//     ajaxOptions.headers = {
//       Authorization: `Bearer ${token}`
//     }
// }});

export default {
  name: "PromoSelectionCard",
  components: {
    DxDropDownBox,
    DxTreeView,
    DxDataGrid,
    DxSelection,
    DxPaging,
    DxFilterRow,
    DxScrolling,
  },
  
  mounted () {
    const url = this.getApiUrl();
    const clientId = this.getClientId();

    this.dataSource = createStore({
      loadMode: "raw",
      loadUrl: `${url}/api/slims-report/get-promo-items`,
      onBeforeSend: (method, ajaxOptions) => {
        const token = this.getTokenSilently();
        ajaxOptions.headers = {
          Authorization: `Bearer ${token}`
        }
        ajaxOptions.data = {
          clientId: clientId
        }

      }});
  },

  data () {
    return {
      dataSource: null,
      isGridBoxOpened: false,
      gridBoxValue: '',
      selectedItem: ''
    };
  },

  methods: {
    onGridSelectionChanged(e) {
      this.selectedItem = e.selectedRowsData[0].itemName;
      this.gridBoxValue = e.selectedRowsData[0].itemName;

      this.eventHub.$emit("promoItemSelection", this.selectedItem);
    },
  }
}
</script>

<style scoped>

</style>
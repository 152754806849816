<template>
  <div class="uk-padding-small">
    <div class="uk-grid-medium uk-margin-bottom" data-uk-grid data-uk-height-match>


      <div class="uk-width-1-1">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto uk-flex">
                <h4>Overall Sales</h4>
              </div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <overall-sales-by-week></overall-sales-by-week>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-2-3">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div v-if="startDate === ''" class="uk-width-auto"><h4>Daily Trend View</h4></div>
              <div v-if="startDate !== ''" class="uk-width-auto"><h4>Daily Trend View</h4> {{startDate}} - {{endDate}}</div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container trend-height">
              <weekly-trend></weekly-trend>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-1-3">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@l">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>Filters</h4></div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <filter-loader></filter-loader>
            </div>
          </div>
        </div>
      </div>
      

    </div>
  </div>
</template>

<script>
import StoreTypeBreakdownBarchart from "./Components/StoreTypeBreakdownBarchart";
import {
  DxRangeSelector,
  DxMargin,
  DxScale,
  DxMinorTick,
  DxMinorTickInterval,
  DxTickInterval,
  DxMinRange,
  DxMaxRange,
  DxSliderMarker,
} from 'devextreme-vue/range-selector';
import axios from "axios";
import OverallSalesByWeek from "./Components/OverallSalesByWeek";
import FilterLoader from "./Components/FilterLoader";
import OverallSalesDetailKpi from "./Components/OverallSalesDetailKpi";
import WeeklyTrend from "./Components/Filters/WeeklyTrend";
export default {
  name: "OverallSalesDetail",
  components: {
    StoreTypeBreakdownBarchart,
    DxRangeSelector,
    DxMargin,
    DxScale,
    DxMinorTick,
    DxMinorTickInterval,
    DxTickInterval,
    DxMinRange,
    DxMaxRange,
    DxSliderMarker,
    OverallSalesByWeek,
    FilterLoader,
    OverallSalesDetailKpi,
    WeeklyTrend
  },

  data() {
    return {
      isLoading: true,
      data: null,
      range: [],
      startDate: '',
      endDate: '',
      firstRun: true
    }
  },

  props: {
    yesterdayDates: Array,
    rolling7Dates: Array,
    periodDates: Array,
    ytdDates: Array
  },
  
  created() {
    this.eventHub.$on('filter', this.updateDates)
  },

  beforeDestroy() {
    this.eventHub.$on('filter', this.updateDates)
  },

  methods: {
    backToOverview() {
      this.eventHub.$emit("backToOverview", true);
    },
    
    updateDates: function (filter) {
      this.startDate = new Date(filter.startDate).toLocaleDateString("en-US");
      this.endDate = new Date(filter.endDate).toLocaleDateString("en-US");
    },
  }

}
</script>

<style scoped>
.grid-buttons {
  height: 30px;
}

.trend-height {
  height: 550px;
}
</style>
<template>
  <DxDataGrid
      v-if="dataSource !== null"
      :data-source="dataSource"
      :show-borders="true"
      @row-prepared="onRowPrepared"
  >

    <DxExport
        :enabled="true"
    />

    <DxScrolling
        mode="infinite"/>

    <DxColumn
        data-field="channel"
        :caption="currentGroupBy"/>

    <DxColumn
        data-field="lunch"
        cell-template="template"/>

    <DxColumn
        data-field="midday"
        cell-template="template"/>

    <DxColumn
        data-field="dinner"
        cell-template="template"/>

    <DxColumn
        data-field="lateNight"
        cell-template="template"/>

    <DxColumn
        data-field="total"
        class="total-column"
        cell-template="templateTotal"/>

    <template #template="{ data }">
      <div :class="isTotalRow(data)">
        <span v-if="data.value < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;</span>
        <span v-if="data.value > 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;</span>
        <span v-if="data.value === 0"
              class="fa fa-minus uk-icon-center uk-text-primary">&nbsp;</span>{{ data.value | formatPercentage }}
      </div>
    </template>

    <template #templateTotal="{ data }">
      <div class="total-column">
        <span v-if="data.value < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;</span>
        <span v-if="data.value > 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;</span>
        <span v-if="data.value === 0"
              class="fa fa-minus uk-icon-center uk-text-primary">&nbsp;</span>{{ data.value | formatPercentage }}
      </div>
    </template>

  </DxDataGrid>
</template>

<script>
import {
  DxDataGrid, DxColumn, DxPaging, DxPager, DxScrolling, DxExport
} from 'devextreme-vue/data-grid';
import {createStore} from "devextreme-aspnet-data-nojquery";

export default {
  name: "CompCalculatorGrid",
  components: {
    DxDataGrid, DxColumn, DxPaging, DxPager, DxScrolling, DxExport
  },

  data() {
    return {
      currentGroupBy: "Channel",
      dataSource: null,
    }
  },

  created() {
    this.eventHub.$on('channel_filter', this.loadDataWithFilters)
    this.eventHub.$on('clearFilter', this.loadData)
  },

  beforeDestroy() {
    this.eventHub.$off('channel_filter');
    this.eventHub.$off('clearFilter');
  },

  
  filters: {
    formatPercentage(number) {
      if (number === undefined)
        return 'Null';

      number = number.toString() + "%";

      return number;
    },
  },

  methods: {
    onRowPrepared: function (e) {
      if (e.rowType === "data" && e.data.groupBy === 'Total') {
        e.rowElement.style.fontWeight = 'Bold';
      }
    },

    isTotalRow(data) {
      if (data.rowType === "data" && data.data.groupBy === 'Total') {
        return "total-column";
      } else
        return "";
    },
    
    loadDataWithFilters (filterObject) {
      const url = this.getApiUrl();
      filterObject.clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/franchise/channel-grid`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = filterObject;

        }
      });
    },
    
    loadData () {
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/franchise/channel-grid`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            clientId: clientId
          }

        }
      });
    }
  },
  
  

  mounted() {
    this.loadData();
  }

}
</script>

<style scoped>
.top-metric {
  font-size: large;
}

.total-column {
  font-weight: bold;
}
</style>
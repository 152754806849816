<template>
  <div class="uk-flex uk-flex-column">

    <div class="uk-text-bold">{{itemData.promotionName}} </div>

    <small-slims-loader v-if="isLoading"></small-slims-loader>
    
    <div v-if="!isLoading && data !== null" class="uk-grid uk-grid-divider uk-grid-small uk-padding-bottom-small uk-flex-nowrap" data-uk-grid>
      <div>
        <span class="uk-text-small"><span data-uk-icon="icon:users"  class="uk-margin-small-right uk-text-primary"></span>GOI %</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary">{{data.goi | formatPercentage}}<span class="small-font">%</span> </h1>
        </div>

      </div>
      
      <div>
        <span class="uk-text-small"><span data-uk-icon="icon: cart" class="uk-margin-small-right uk-text-primary"></span>PSPD</span>
        <div>
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary">{{data.pspd | formatPercentage}}</h1>
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span data-uk-icon="icon: calendar" class="uk-margin-small-right uk-text-primary"></span>Date Range</span>
        <div class="parent">
          <h5 class="uk-margin-remove uk-text-primary child">{{new Date(itemData.startDate).toLocaleDateString("en-US")}} <i class="fa fa-arrow-right" aria-hidden="true"></i></h5>
          <h5 class="uk-margin-remove lower-date uk-text-primary">{{this.promotionToDate}}</h5>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import axios from "axios";
import SmallSlimsLoader from "./SmallSlimsLoader";
export default {
  name: "CompSalesCard",
  components: {
    SmallSlimsLoader
  },
  
  props: {
    itemData: {
      type: Object,
      default: () => ({}),
    },
  },

  data () {
    return {
      isLoading: false,
      data: null,
      today: new Date(),
      promotionToDate: this.checkEndDate()
    }
  },

  methods: {
    checkEndDate() {
      let today = new Date();
      
      if (new Date(this.itemData.endDate) >= today) {
        return today.toLocaleDateString("en-US").toString();
      }
      else {
        return new Date(this.itemData.endDate).toLocaleDateString("en-US").toString();
      }
    }
   },


  filters: {
    formatPercentage (number) {
      if (number === null || number === undefined)
        return 'N / A';

      number = number.toFixed(1);
      number = number.toString();

      return number;
    },

    formatMoney: function (number) {
      if (number === null || number === undefined)
        return 'N / A';
      return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}).format(number);
    }
  },

  mounted() {
    this.isLoading = true;
    let me = this;
    const token = this.getTokenSilently();
    const url = this.getApiUrl();
    const clientId = this.getClientId();

    axios.get(`${url}/api/slims-report/promotions/get-promotion-metrics`, {
      headers: {Authorization: `Bearer ${token}`},
      params: {
        plu: this.itemData.plu,
        startDate: this.itemData.startDate,
        endDate: this.itemData.endDate,
        clientId: clientId
      }
    })
        .then(response => {
          me.data = response.data[0];
        })
        .catch(err => {
        })
        .finally(() => {
          me.isLoading = false;
        });
  },
}
</script>

<style scoped>
.clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}


.small-text-css {
  margin-top: -20px;
}

div {
  white-space: pre;
}

.lower-date {
  margin-top: -10px;
  margin-bottom: 10px;
}

.small-font {
  font-size: 20px;
}

.child {
  /* ... */
  position: absolute;
  top: 0;
  left: 0;
}

.parent {
  position: relative;
}

</style>
<template>
  <div class="uk-align-center">
    <ul class="cloud" role="navigation" aria-label="Webdev word cloud">
      <li><a href="#" data-weight="4">place</a></li>
      <li><a href="#" data-weight="1">slow</a></li>
      <li><a href="#" data-weight="5">order</a></li>
      <li><a href="#" data-weight="8">chicken</a></li>
      <li><a href="#" data-weight="6">customer</a></li>
      <li><a href="#" data-weight="4">hot</a></li>
      <li><a href="#" data-weight="5">friendly</a></li>
      <li><a href="#" data-weight="6">service</a></li>
      <li><a href="#" data-weight="2">fresh</a></li>
      <li><a href="#" data-weight="9">food</a></li>
      <li><a href="#" data-weight="3">fast</a></li>
      <li><a href="#" data-weight="7">tenders</a></li>
      <li><a href="#" data-weight="8">Great</a></li>
      <li><a href="#" data-weight="1">drive</a></li>
      <li><a href="#" data-weight="3">customer</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "WordCloud"
}
</script>

<style scoped>

html, body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.cloud {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  line-height: 2.75rem;
  width: 450px;
}

ul.cloud a {
  /*   
  Not supported by any browser at the moment :(
  --size: attr(data-weight number); 
  */
  --size: 4;
  --color: #a33;
  color: var(--color);
  font-size: calc(var(--size) * 0.25rem + 0.5rem);
  display: block;
  padding: 0.125rem 0.25rem;
  position: relative;
  text-decoration: none;
  /* 
  For different tones of a single color
  opacity: calc((15 - (9 - var(--size))) / 15); 
  */
}

ul.cloud a[data-weight="1"] { --size: 1; }
ul.cloud a[data-weight="2"] { --size: 2; }
ul.cloud a[data-weight="3"] { --size: 3; }
ul.cloud a[data-weight="4"] { --size: 4; }
ul.cloud a[data-weight="5"] { --size: 6; }
ul.cloud a[data-weight="6"] { --size: 8; }
ul.cloud a[data-weight="7"] { --size: 10; }
ul.cloud a[data-weight="8"] { --size: 13; }
ul.cloud a[data-weight="9"] { --size: 16; }

ul[data-show-value] a::after {
  content: " (" attr(data-weight) ")";
  font-size: 1rem;
}

ul.cloud a[data-weight="1"] { --color: #181; }
ul.cloud a[data-weight="2"]{ --color: #c38; }
ul.cloud a[data-weight="3"] { --color: #181; }
ul.cloud a[data-weight="4"]{ --color: #c38; }
ul.cloud a[data-weight="5"] { --color: #181; }
ul.cloud a[data-weight="6"]{ --color: #c38; }
ul.cloud a[data-weight="7"] { --color: #181; }
ul.cloud a[data-weight="8"]{ --color: #181; }
ul.cloud a[data-weight="9"] { --color: #181; }


ul.cloud a:focus {
  outline: 1px dashed;
}

ul.cloud a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 100%;
  background: var(--color);
  transform: translate(-50%, 0);
  opacity: 0.15;
  transition: width 0.25s;
}

ul.cloud a:focus::before,
ul.cloud a:hover::before {
  width: 100%;
}

@media (prefers-reduced-motion) {
  ul.cloud * {
    transition: none !important;
  }
}
</style>
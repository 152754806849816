<template>
  <div>

    <div class="uk-position-center uk-flex">
      <small-slims-loader v-if="isLoading"></small-slims-loader>
      <label v-if="isLoading">Loading Promotions...</label>
    </div>


    <div v-if="!isLoading" v-for="item in data" :value="item" :key="item.plu">
      <div class="selected-promotion uk-card-hover promotion-padding" :uk-tooltip="'View '+ item.promotionName +' Detail'" @click="goToPromotionDetail(item)">
        <promotions-metric :itemData="item"></promotions-metric>
        <div class="uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom uk-text-bold"></div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import PromotionsMetric from "./Components/PromotionsMetric";
import SmallSlimsLoader from "./Components/SmallSlimsLoader";
export default {
  name: "PromotionsCard",
  components: {PromotionsMetric, SmallSlimsLoader},

  data() {
    return {
      isLoading: false,
      data: null,
      promotionItemNames: []
    }
  },

  methods: {
    goToPromotionDetail(promotion) {
      this.eventHub.$emit("showPromoDetail", promotion);
    }
  },

  mounted() {

    this.isLoading = true;
    let me = this;
    const token = this.getTokenSilently();
    const url = this.getApiUrl();
    const clientId = this.getClientId();

    axios.get(`${url}/api/slims-report/promotions/get-previous-promotion-items`, {
      headers: {Authorization: `Bearer ${token}`},
      params: {clientId: clientId}
    })
        .then(response => {
          me.data = response.data;
        })
        .catch(err => {
        })
        .finally(() => {
          me.isLoading = false;


        });
  },
}
</script>

<style scoped>

.selected-promotion:hover{
  border: 1px solid #1e87ef;
  cursor: pointer;
}

.promotion-padding {
  padding-left: 5px;
  padding-right: 5px;
}

</style>
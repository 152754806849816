<template>
  <div class="uk-padding-small">
    <div class="uk-grid-medium uk-margin-bottom" data-uk-grid data-uk-height-match>


      <div class="uk-width-1-1">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>Period Detail </h4>{{ periodDates[0] + ` - ` + periodDates[1] }}</div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <period-level1 :is-corporate="isCorporate"></period-level1>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-3-5@l">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>Period Barchart</h4></div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <comp-sales-barchart :is-corporate="isCorporate" time-period="period-bar"></comp-sales-barchart>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-2-5@l">
        <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>Period Store Ranking </h4>{{ periodDates[0] + ` - ` + periodDates[1] }}</div>
              <div class="uk-width-expand uk-text-right panel-icons">
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <store-rank-data-grid :rank-type="'Period'"></store-rank-data-grid>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import PeriodLevel1 from "./Components/PeriodLevel1";
import StoreRankDataGrid from "../Shared/StoreRankDataGrid";
import CompSalesBarchart from "../Shared/CompSalesBarchart";

export default {
  name: "PeriodDetailApp",
  components: {PeriodLevel1, CompSalesBarchart, StoreRankDataGrid},
  props: {
    isCorporate: Boolean,
    periodDates: Array,
  },
  methods: {
    backToOverview() {
      this.eventHub.$emit("backToOverview", true);
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="uk-inline help-style" >
    <button type="button" class="uk-button uk-button-link">
      <span class="fa fa-question-circle uk-icon-center"></span>
    </button>
    <div data-uk-drop="mode: click">
      <div class="uk-card uk-card-body uk-card-default uk-card-small">
        {{helpText}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelpInfo",
  props: [
      'helpText'
  ]
}
</script>

<style scoped>

</style>
<template>
  <div>
    <small-slims-loader v-if="dataSource === null" class="uk-padding-small uk-position-top-right"> Loading Graph</small-slims-loader>
    <div v-if="dataSource !== null">
      <DxDataGrid
          :data-source="dataSource"
          :show-borders="true"
          height="700px"
          @row-prepared="onRowPrepared"
          @exporting="onExporting"
          @toolbar-preparing="onToolbarPreparing($event)">

        <DxScrolling
            mode="infinite"/>

        <DxExport
            :enabled="true"
        />

        <DxColumn
            class="uk-text-center"
            data-field="groupBy"
            :caption="groupByCaption"/>

        <DxColumn
            class="uk-text-center"
            data-field="lunch"
            cell-template="template"/>

        <DxColumn
            class="uk-text-center"
            data-field="midday"
            cell-template="template"/>

        <DxColumn
            data-field="dinner"
            cell-template="template"/>

        <DxColumn
            data-field="lateNight"
            cell-template="template"/>

        <DxColumn
            data-field="total"
            class="total-column"
            cell-template="templateTotal"/>

        <template #template="{ data }">
          <div :class="{'total-column': isTotalRow(data), 'above-zero': data.value > 0, 'below-zero': data.value < 0}">
            <div class="uk-padding-small uk-padding-remove-vertial uk-align-center uk-text-center font-size">
              {{ data.value | formatPercentage }}
            </div>
          </div>
        </template>

        <template #templateTotal="{ data }">
          <div :class="{'total-column': true, 'above-zero': data.value > 0, 'below-zero': data.value < 0}">
            <div class="uk-padding-small uk-align-center uk-text-center font-size">{{
                data.value | formatPercentage
              }}
            </div>
          </div>
        </template>

      </DxDataGrid>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid, DxColumn, DxPaging, DxPager, DxScrolling, DxExport
} from 'devextreme-vue/data-grid';
import {createStore} from "devextreme-aspnet-data-nojquery";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import axios from "axios";
import SmallSlimsLoader from "../../../../Homepage/Components/SmallSlimsLoader";
export default {
  name: "CompCalculatorGrid",
  components: {
    DxDataGrid, DxColumn, DxPaging, DxPager, DxScrolling, DxExport, SmallSlimsLoader
  },

  data() {
    return {
      currentGroupBy: "Channel",
      groupByCaption: "Channel",
      currentMetric: "NetSales",
      currentFranchiseGroup: "All",
      isLoading: false,
      franchiseFilter: [],
      dataSource: null,

      metrics: [
        {
          text: "Sales",
          value: "NetSales"
        },

        {
          text: "Traffic",
          value: "GuestCount"
        },
      ],


      items: [
        {
          text: "Channel",
          value: "main.Channel"
        },

        {
          text: "Store Name",
          value: "main.StoreName"
        }
      ],

      timeItems: [
        {
          text: "Yesterday",
          value: "Yesterday"
        },

        {
          text: "Rolling 7",
          value: "Rolling 7"
        },

        {
          text: "Period",
          value: "Period"
        },

        {
          text: "YTD",
          value: "YTD"
        },
      ]
    }
  },

  props: {
    groupBy: String,
    timeRange: String,
  },

  filters: {
    formatPercentage(number) {
      if (number === undefined)
        return 'Null';

      number = number.toString() + "%";

      return number;
    },
  },

  methods: {

    metricChanged(e) {
      this.currentMetric = e.value;
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/franchise/comp-calculator`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            groupBy: this.groupBy,
            timeRange: this.timeRange,
            metric: this.currentMetric,
            franchise: this.currentFranchiseGroup,
            clientId: clientId
          }

        }
      });
    },

    groupChanged(e) {
      this.currentGroupBy = e.value;
      let obj = this.items.find(o => o.value === e.value);
      this.groupByCaption = obj.text;
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/franchise/comp-calculator`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            groupBy: e.value,
            timeRange: this.timeRange,
            metric: this.currentMetric,
            franchise: this.currentFranchiseGroup,
            clientId: clientId
          }

        }
      });
    },

    onExporting(e) {
      let groupBy = this.currentGroupBy;
      let timeRange = this.timeRange

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Contribution To Comp');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet
      }).then(function (dataGridRange) {
        for (let x = 2; x < dataGridRange.to.column + 1; x++) {
          for (let y = 2; y < dataGridRange.to.row + 1; y++) {

            let cell = worksheet.getRow(y).getCell(x)

            let cell2 = cell.value

            let cellValue = Number(cell2).toFixed(1);

            Object.assign(
                worksheet.getRow(y).getCell(x),
                {value: cellValue + "%"}
            );
          }
        }
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], {type: "application/octet-stream"}),
              groupBy + ' Comp Calculator' + ' (' + timeRange + ')' + '.xlsx');
        });

      });
    },

   

    timeChanged(e) {

      this.timeRange = e.value
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/franchise/comp-calculator`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            groupBy: this.currentGroupBy,
            timeRange: e.value,
            metric: this.currentMetric,
            franchise: this.currentFranchiseGroup,
            clientId: clientId
          }

        }
      });
    },


    onRowPrepared: function (e) {
      if (e.rowType === "data" && e.data.groupBy === 'Total') {
        e.rowElement.style.fontWeight = 'Bold';
      }
    },

    isTotalRow(data) {
      if (data.rowType === "data" && data.data.groupBy === 'Total') {
        return true;
      } else
        return false;


    },


    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
            location: 'before',
          }, {
            location: 'before',
            widget: 'dxSelectBox',
            options: {
              width: 200,
              placeHolder: "Select a group by",
              items: this.items,
              displayExpr: 'text',
              valueExpr: 'value',
              value: this.items[0].value,
              onValueChanged: this.groupChanged.bind(this)
            }
          },

          {
            location: 'before',
            widget: 'dxSelectBox',
            options: {
              width: 200,
              placeHolder: "Select a time range",
              items: this.timeItems,
              displayExpr: 'text',
              valueExpr: 'value',
              value: this.timeRange,
              onValueChanged: this.timeChanged.bind(this)
            }
          },

          {
            location: 'before',
            widget: 'dxSelectBox',
            options: {
              width: 200,
              placeHolder: "Select a Metric",
              items: this.metrics,
              displayExpr: 'text',
              valueExpr: 'value',
              value: this.metrics[0].value,
              onValueChanged: this.metricChanged.bind(this)
            }
          },
      );
    },

  },

  mounted() {
    const url = this.getApiUrl();
    const clientId = this.getClientId();

    // const token = this.getTokenSilently();
    // axios.get(`${url}/api/slims-report/total-group-filter`, {
    //   headers: {Authorization: `Bearer ${token}`},
    // }).then(response => {
    //   for (let x = 0; x < response.data.length; x++) {
    //     this.franchiseFilter.push(response.data[x])
    //   }
    // });

    this.dataSource = createStore({
      loadUrl: `${url}/api/slims-report/franchise/comp-calculator`,
      onBeforeSend: (method, ajaxOptions) => {
        const token = this.getTokenSilently();
        ajaxOptions.headers = {
          Authorization: `Bearer ${token}`
        }
        ajaxOptions.data = {
          groupBy: "Channel",
          timeRange: this.timeRange,
          metric: "NetSales",
          franchise: "All",
          clientId: clientId
        }

      }
    });
  }

}
</script>

<style scoped>
.top-metric {
  font-size: large;
}

.above-zero {
  background-color: rgba(63, 195, 128, 0.5);
}

.below-zero {
  background-color: rgba(255, 0, 47, 0.5);
}

.font-size {
  font-size: 1.1em;
}

.total-column {
  font-weight: bold;
}
</style>
<template>
  <DxChart
      class="chart-height"
      v-if="!isLoading"
      :data-source="dataSource"
  >

    <DxTooltip
        :enabled="true"
        :customize-tooltip="customizeTooltip"
    >

      <DxFormat
          type="fixedPoint"
          :precision="1"
      />

    </DxTooltip>

    <DxCommonSeriesSettings
        argument-field="timePeriod"
        type="line">

    </DxCommonSeriesSettings>
    
    <DxSeries
        argument-field="timePeriod"
        value-field="netSales"
        name="Net Sales"
        type="bar"
        color="#1bb2f5"
        :showInLegend="true"/>

    <DxSeries
        argument-field="timePeriod"
        value-field="trendLine"
        name="Trend"
        dash-style="dash"
        type="line"
        color="#000000"
        :showInLegend="true"/>

    <DxArgumentAxis
        :title="getTimePeriodTitle(timePeriod)">
    </DxArgumentAxis>

    <DxValueAxis
        title="Net Sales $"
    />

  </DxChart>
</template>

<script>
import {
  DxAggregation,
  DxAnnotation,
  DxArgumentAxis,
  DxChart,
  DxCommonSeriesSettings,
  DxConstantLine,
  DxFormat,
  DxSeries,
  DxTooltip,
  DxValueAxis
} from 'devextreme-vue/chart';

import axios from "axios";

export default {
  name: "ChannelChart",
  components: {
    DxChart,
    DxSeries,
    DxTooltip,
    DxArgumentAxis,
    DxValueAxis,
    DxConstantLine,
    DxAnnotation,
    DxFormat, 
    DxAggregation, 
    DxCommonSeriesSettings,
    
  },
 

  data () {
    return {
      data: null,
      dataSource: null,
      isLoading: false,
      timePeriod: "YTD",
      dollarFormat: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',})
    };
  },

  created() {
    this.eventHub.$on('channel_filter', this.loadDataWithFilters)
    this.eventHub.$on('clearFilter', this.loadData)
  },

  beforeDestroy() {
    this.eventHub.$off('channel_filter');
    this.eventHub.$off('clearFilter');
  },


  mounted () {
    this.loadData();
  },

  methods: {
    customizeTooltip(pointsInfo) {
      let number = pointsInfo.value
      return {
        text: `${pointsInfo.seriesName}: ${this.dollarFormat.format(number)}`,
      };
    },

    getTimePeriodTitle(tp) {
      if (tp === 'Yesterday')
        return "Yesterday"
      if (tp === 'YTD')
        return "YTD (Weeks)"
      if (tp === 'Rolling 7')
        return "Rolling 7 (Days)"
      if (tp === 'Period')
        return "Period (Weeks)"
    },

    loadDataWithFilters(filterObject) {
      this.timePeriod = filterObject.timePeriod;
      this.isLoading = true;
      
      const token = this.getTokenSilently();
      const url = this.getApiUrl();
      filterObject.clientId = this.getClientId()

      axios.get(`${url}/api/slims-report/channel-chart`, {
        headers: {Authorization: `Bearer ${token}`},
        params: filterObject
      }).then(response => {
        this.dataSource = response.data;
        this.isLoading = false;
      });
    },

    loadData() {
      this.isLoading = true;
      const token = this.getTokenSilently();
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      axios.get(`${url}/api/slims-report/channel-chart`, {
        headers: {Authorization: `Bearer ${token}`},
        params: {clientId: clientId}
      }).then(response => {
        this.dataSource = response.data;
        this.isLoading = false;
      });

    }
  }
};
</script>

<style scoped>

.chart-height {
  height: 450px
}

</style>
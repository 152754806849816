<template>
  <div class="uk-flex uk-flex-column">
    <small-slims-loader v-if="isLoading && compData === null"></small-slims-loader>

    <div v-if="!isLoading && compData !== null" class="uk-grid uk-grid-divider uk-padding-bottom-small uk-flex-nowrap uk-grid-medium" data-uk-grid>
      <div>
        <span class="uk-text-small"><span data-uk-icon="icon:clock" :data-uk-tooltip="yesterdayDates[0]" class="uk-margin-small-right uk-text-primary"></span>Yesterday</span>
        <div class="clickable" @click="getEmitString('Franchise', 'Yesterday')" :data-uk-tooltip="getToolTip('Franchise', 'Yesterday')">
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary">{{ compData.pctChangeYesterday | fixToOneDecimal }}<span class="small-font">%</span></h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(compData.changeYesterday)" :data-uk-icon="getIcon(compData.changeYesterday)">{{ compData.changeYesterday | formatMoneyNoDecimal }}</span> from LY
        </div>
      </div>
      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i
            v-if="rolling7Dates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`"
            class="fa fa-calendar-minus-o" aria-hidden="true"/><i v-if="rolling7Dates[0] !== undefined" :data-uk-tooltip="`title:` + rolling7Dates[0] + ` - ` + rolling7Dates[1]" class="fa fa-calendar-minus-o" aria-hidden="true"/></span>Rolling 7 Day Average</span>
        <div class="clickable" @click="getEmitString('Franchise', 'Rolling7')" :data-uk-tooltip="getToolTip('Franchise', 'Rolling7')">
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary">{{ compData.pctChangeRolling7 | fixToOneDecimal }}<span class="small-font">%</span></h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(compData.changeRolling7)" :data-uk-icon="getIcon(compData.changeRolling7)">{{ compData.changeRolling7 | formatMoneyNoDecimal }}</span> from LY
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i
            v-if="periodDates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`"
            class="fa fa-calendar-o" aria-hidden="true"></i><i v-if="periodDates[0] !== undefined" :data-uk-tooltip="`title:` + periodDates[0] + ` - ` + periodDates[1]" class="fa fa-calendar-o" aria-hidden="true"></i></span>Period</span>
        <div class="clickable" @click="getEmitString('Franchise', 'Period')" :data-uk-tooltip="getToolTip('Franchise', 'Period')">
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary">{{compData.pctChangePeriod | fixToOneDecimal }}<span class="small-font">%</span></h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(compData.changePeriod)"
                :data-uk-icon="getIcon(compData.changePeriod)">{{ compData.changePeriod | formatMoneyNoDecimal }}</span> from LY
        </div>
      </div>

      <div>
        <span class="uk-text-small"><span class="uk-margin-small-right uk-text-primary"><i
            v-if="ytdDates[0] === undefined" :data-uk-tooltip="`title: Loading Date Range...`" class="fa fa-calendar"
            aria-hidden="true"></i><i v-if="ytdDates[0] !== undefined"
                                      :data-uk-tooltip="`title:` + ytdDates[0] + ` - ` + ytdDates[1]"
                                      class="fa fa-calendar" aria-hidden="true"></i></span>YTD</span>
        <div class="clickable" @click="getEmitString('Franchise', 'YTD')":data-uk-tooltip="getToolTip('Franchise', 'YTD')">
          <h1 class="uk-heading-primary uk-margin-remove uk-text-primary">{{compData.pctChangeYtd | fixToOneDecimal}}<span class="small-font">%</span></h1>
        </div>
        <div class="uk-text-small ">
          <span :class="getTextColor(compData.changeYtd)"
                :data-uk-icon="getIcon(compData.changeYtd)">{{ compData.changeYtd | formatMoneyNoDecimal }}</span> from LY
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallSlimsLoader from "./SmallSlimsLoader";
export default {
  name: "CompSalesMetric",
  components: {
    SmallSlimsLoader
  },
  
  data () {
    return {
      isLoading: false
    }
  },
  
  props: {
    compData: {
      type: Object,
      default: () => ({}),
    },

    yesterdayDates: Array,
    rolling7Dates: Array,
    periodDates: Array,
    ytdDates: Array
  },
  
  methods: {
    goToDetail(metric) {
      this.eventHub.$emit("compDetailPage", metric);
    },
    
     getToolTip (type, time) {
      if (type === 'Franchise') {
        if (time === 'Yesterday') {
          return "View Yesterday Detail";
        }

        if (time === 'Rolling7') {
          return "View Roll. 7 Detail";
        }

        if (time === 'Period') {
          return "View Period Detail";
        }

        if (time === 'YTD') {
          return "View YTD Detail";
        }
      }

      if (type === 'Corporate Stores') {
        if (time === 'Yesterday') {
          return "Go To Corporate Detail (Yesterday)";
        }

        if (time === 'Rolling7') {
          return "Go To Corporate Detail (Roll. 7)";
        }

        if (time === 'Period') {
          return "Go To Corporate Detail (Period)";
        }

        if (time === 'YTD') {
          return "Go To Corporate Detail (YTD)";
        }
      }
    },
    
    getEmitString(type, time) {
      if (type === 'Franchise') {
        if (time === 'Yesterday') {
          this.eventHub.$emit("compDetailPage", 'yesterday');
        }

        if (time === 'Rolling7') {
          this.eventHub.$emit("compDetailPage", 'rolling7');
        }

        if (time === 'Period') {
          this.eventHub.$emit("compDetailPage", 'period');
        }

        if (time === 'YTD') {
          this.eventHub.$emit("compDetailPage", 'ytd');
        }
      }
    },

    goToCompCalc(view) {
      this.eventHub.$emit("compCalc", view);
    },

    getTextColor(data) {
      if (data > 0)
        return "uk-text-success"
      else
        return "uk-text-danger"
    },

    getIcon(data) {
      if (data > 0)
        return "icon: triangle-up"
      else
        return "icon: triangle-down"
    },
  },
  
}
</script>

<style scoped>
.clickable:hover {
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 1px;
  cursor: pointer;
}

.system-total-clickable:hover {
  cursor: pointer;
}

div {
  white-space: pre;
}

.small-font {
  font-size: 20px;
}

</style>
<template>
  <div>
    <div class="center-inside">
      <div class="uk-width-1-1 center-inside">
        <div class="uk-card uk-card-default uk-card-small">
          <div class="uk-card-header">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-auto"><h4>Contribution To Comp Calculator</h4></div>
            </div>
          </div>
          <div class="uk-card-body">
            <div class="chart-container">
              <comp-calculator-grid :group-by="groupBy" :time-range="timeRange"></comp-calculator-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompCalculatorGrid from "./Components/CompCalculatorGrid";

export default {
  name: "CompCalcApp",
  components: {
    CompCalculatorGrid
  },

  props: {
    timeRange: String
  },

  data() {
    return {
      groupBy: "Channel"
    }
  }
}
</script>

<style scoped>
.center-inside {
  display: block;
  margin: auto;
  padding: 3px;
}
</style>
<template>
  <DxDataGrid
      :show-borders="true"
      :data-source="dataSource"
      height="400px"
      :allow-column-resizing="true"
  >
    <DxFilterRow :visible="true"/>
    <DxHeaderFilter :visible="true"/>

    <DxExport
        :enabled="true"
    />

    <DxColumn
        data-field="rank"
        caption=""
        cell-template="rank"
        width="35px"/>

    <DxColumn
        data-field="rankChg"
        caption=""
        cell-template="rankChg"
        width="60px"/>
    <DxScrolling mode="infinite"/>

    <DxColumn
        data-field="groupName"
        caption="Group Name"/>

    <DxColumn
        data-field="storeName"
        caption="Store Name"/>

    <DxColumn
        data-field="totalCompPct"
        caption="Comp %"
        :format="formatPercentage"/>

    <DxColumn
        data-field="totalChg"
        caption="Comp Difference"
        :format="formatMoney"/>

    <template #rank="{ data }">
      <div>
        <span class="top-metric">{{ data.value }}</span>
      </div>
    </template>

    <template #rankChg="{ data }">
      <div>
        <span v-if="data.value < 0"
              class="top-metric fa fa-chevron-down uk-icon-center uk-text-danger">{{ data.value }}</span>
        <span v-if="data.value > 0"
              class="top-metric fa fa-chevron-up uk-icon-center uk-text-success">{{ data.value }}</span>
        <span v-if="data.value === 0" class="top-metric uk-icon-center uk-text-primary"><i class="fa fa-minus"
                                                                                           aria-hidden="true"></i></span>
      </div>
    </template>

  </DxDataGrid>
</template>

<script>
import {
  DxColumn,
  DxDataGrid,
  DxMasterDetail,
  DxFilterRow,
  DxHeaderFilter,
  DxScrolling,
  DxExport
} from 'devextreme-vue/data-grid';
import {createStore} from "devextreme-aspnet-data-nojquery";

export default {
  name: "StoreRankingDataGrid",
  components: {
    DxDataGrid,
    DxColumn,
    DxMasterDetail,
    DxFilterRow,
    DxHeaderFilter,
    DxScrolling,
    DxExport
  },

  data() {
    return {
      dataSource: null
    }
  },

  props: {
    rankType: String
  },

  mounted() {
    this.loadData();
  },

  methods: {

    formatPercentage(diff) {
      let result = diff.toFixed(1);
      result = result.toString() + "%";
      return result;
    },

    formatMoney(number) {
      if (number == null)
        return 'Unknown';
      return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0}).format(number);
    },

    loadData() {
      const url = this.getApiUrl();
      const clientId = this.getClientId();
      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/franchise/store-ranking`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }

          ajaxOptions.data = {
            rankType: this.rankType,
            clientId: clientId
          };


        }
      });
    }
  }
}
</script>

<style scoped>

.top-metric {
  font-size: large;
}

</style>
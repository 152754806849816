const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('access_token');
const apiUrl = 'https://api-legacy-csharp.engine.net'
// const apiUrl = 'https://localhost:7020'
const clientId = '08279bb1-d7bf-43c7-801f-1ad890c5207c' // Franchise ClientID

export const AuthPlugin = {
    install(Vue, options) {
        Vue.prototype.getTokenSilently = () => {
            return token;
        }
        Vue.prototype.getApiUrl = () => {
            return apiUrl;
        }
        Vue.prototype.getClientId = () => {
            return clientId;
        }
    },
}

<template>
  <div>

    <div class="uk-position-center uk-flex">
      <small-slims-loader v-if="isLoading"></small-slims-loader>
      <label v-if="isLoading">Loading Promotions...</label>
    </div>


    <div v-if="!isLoading" v-for="item in data" :value="item" :key="item.plu">

      <div v-if="new Date(item.startDate) < today" class="selected-promotion uk-card-hover promotion-padding"
           :uk-tooltip="'View '+ item.promotionName +' Detail'" @click="goToPromotionDetail(item)">
        <promotions-metric :itemData="item"></promotions-metric>
        <div class="uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom uk-text-bold"></div>
      </div>

      <div v-if="new Date(item.startDate) >= today"
           class="uk-card-hover selected-promotion-not-started promotion-padding" :uk-tooltip="notStartedToolTip(item)">
        <promotions-metric :itemData="item"></promotions-metric>
        <div class="uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom uk-text-bold"></div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import PromotionsMetric from "./Components/PromotionsMetric";
import SmallSlimsLoader from "./Components/SmallSlimsLoader";

export default {
  name: "PromotionsCard",
  components: {PromotionsMetric, SmallSlimsLoader},

  data() {
    return {
      isLoading: false,
      data: null,
      today: new Date(),
      promotionItemNames: []
    }
  },

  methods: {
    goToPromotionDetail(promotion) {
      this.eventHub.$emit("showPromoDetail", promotion);
    },

    notStartedToolTip(promo) {

      let promoStartDate = new Date(promo.startDate).toLocaleDateString("en-US");

      let tooltip = "Promotion Starts " + promoStartDate;

      return tooltip.toString();
    }
  },

  mounted() {

    this.isLoading = true;
    let me = this;
    const token = this.getTokenSilently();
    const url = this.getApiUrl();
    const clientId = this.getClientId();

    axios.get(`${url}/api/slims-report/promotions/get-promotion-items`, {
      headers: {Authorization: `Bearer ${token}`},
      params: {
        client_id: clientId,
      }
    })
        .then(response => {
          me.data = response.data;

          let activePromotions = [];
          for (let x = 0; x < me.data.length; x++) {
            if (new Date(me.data[x].startDate) < this.today) {
              activePromotions.push(me.data[x]);
            }
          }

          this.eventHub.$emit("navbarPromotions", activePromotions);
        })
        .catch(err => {
        })
        .finally(() => {
          me.isLoading = false;
        });
  },
}
</script>

<style scoped>

.selected-promotion:hover {
  border: 1px solid #1e87ef;
  cursor: pointer;
}

.selected-promotion-not-started:hover {
  border: 1px solid #f5564a;
  cursor: pointer;
}

.promotion-padding {
  padding-left: 5px;
  padding-right: 5px;
}

</style>
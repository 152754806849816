<template>
  <div>
    <DxChart
        v-if="dataSource !== null"
        :data-source="dataSource"
        height="700px"
    >
      <DxSeries
          argument-field="timePeriod"
          value-field="compPct"
          name="Comp %"
          type="bar"
          color="#b3d6f9"
      />

      <DxTooltip
          :enabled="true"
          :format="percentageFormat"
      />

      <DxValueAxis
          :show-zero="true"
          :value-margins-enabled="false"
          name="compPct"
          position="left"
      >
        <DxLabel :customize-text="customizePercentageText"/>
      </DxValueAxis>
    </DxChart>
  </div>
</template>
<script>
import {DxChart, DxFormat, DxGrid, DxLabel, DxSeries, DxTooltip, DxValueAxis} from 'devextreme-vue/chart';
import {createStore} from "devextreme-aspnet-data-nojquery";
import numeral from "numeral";

export default {
  name: "CompSalesBarchart",
  components: {
    DxChart,
    DxSeries,
    DxTooltip,
    DxValueAxis,
    DxFormat,
    DxLabel,
    DxGrid
  },

  props: {
    isCorporate: Boolean,
    timePeriod: String
  },

  data() {
    return {
      dataSource: null,
      isLoading: false,
      dollarFormat: { style: 'currency', currency: 'USD'},
    };
  },

  mounted () {
    this.loadData();
  },

  methods: {
    percentageFormat (value) {
      return numeral(value).format('0.0%')
    },

    customizePercentageText({ valueText }) {
      return `${(valueText * 100).toFixed(0)}%`;
    },

    getValueAxis(value) {
    },

    loadData() {
      const url = this.getApiUrl();
      const clientId = this.getClientId();
      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/franchise/${this.timePeriod}`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }

          ajaxOptions.data = {
            isCorporate: this.isCorporate,
            clientId: clientId
          };
        }
      });
    }
  }
};
</script>
<style>
#chart {
  height: 440px;
}


.height-div {
  height: 200px;
}
</style>
<template>
  <div class="uk-margin">
    <label>
      {{ label }}
    </label>
    <div>
      <div uk-grid>
        <div class="uk-width-auto uk-display-inline">
          <div>
            <DxDateBox
                v-model:value="inputVal.start"
                type="date"
                placeholder="Start Date"
            />
          </div>
        </div>

        <div class="uk-width-auto">
          <span uk-icon="icon:  arrow-right; ratio: 2"></span>
        </div>

        <div class="uk-width-auto">
          <div>
            <DxDateBox
                v-model:value="inputVal.end"
                type="date"
                placeholder="End Date"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DxDateBox from 'devextreme-vue/date-box';

export default {
  name: "StorePeriodSelection",
  components: {

    DxDateBox,

  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        this.storePeriodChanged();
      }
    }
  },
  props: {
    label: String,
    helpText: String,
    placeholder: String,
    value: {},
  },

  methods: {},

  data() {
    return {}
  }
}
</script>

<style scoped>

.red-outline {
  border: 1px solid red;
}

</style>
<template>
    <DxChart
        v-if="data !== null"
        :data-source="data"
        palette="Material"
    >
      <DxCommonSeriesSettings
          argument-field="storeType"
          value-field="netSales"
          type="bar"
          :ignore-empty-points="true">
     
        <DxLabel
            :visible="true"
            :customize-text="formatLabel"
            position="columns"
        >
          <DxConnector
              :visible="true"
              :width="0.5"
          />
        </DxLabel>
      </DxCommonSeriesSettings>
      <DxSeriesTemplate name-field="storeType"/>
    </DxChart>
</template>

<script>

import DxChart, {
  DxSize,
  DxSeries,
  DxLabel,
  DxConnector,
  DxExport,
  DxFont,
  DxLegend,
  DxCommonSeriesSettings,
  DxSeriesTemplate
    
} from 'devextreme-vue/chart';
import {createStore} from "devextreme-aspnet-data-nojquery";
import axios from "axios";

export default {
  name: "CompVsNonCompPieChart",
  components: {
    DxChart,
    DxSize,
    DxSeries,
    DxLabel,
    DxConnector,
    DxLegend,
    DxFont,
    DxExport,
    DxCommonSeriesSettings,
    DxSeriesTemplate
  },

  created () {
    this.eventHub.$on('filter', this.loadDataFilters);
    this.eventHub.$on('clearFilter', this.loadData);

  },

  beforeDestroy() {
    this.eventHub.$off('filter');
    this.eventHub.$off('clearFilter');

  },

  data() {
    return {
      data: null
    }
  },

  methods: {
    
    formatLabel (pointInfo) {
      let num = pointInfo.valueText;


      if (num > 1000 && num < 1000000)
        return Math.abs(num) > 999 ? '$' + Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + ' k' : Math.sign(num) * Math.abs(num);

      if (num > 1000000)
        return Math.abs(num) > 999 ? '$' + Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)) + ' m' : Math.sign(num) * Math.abs(num);
      
    },

    loadDataFilters (filterObject) {
      const url = this.getApiUrl();
      filterObject.clientId = this.getClientId();
      this.data = createStore({
        loadUrl: `${url}/api/slims-report/pie-chart`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`,
          }

          ajaxOptions.data = filterObject
        }
      });
    },
    
    
    loadData() {
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.data = createStore({
        loadUrl: `${url}/api/slims-report/pie-chart`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            clientId: clientId
          }
        }
      });
    }
  },

  mounted() {
    // this.loadData();
  }
}
</script>

<style scoped>

</style>
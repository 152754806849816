<template>
  <div>
    <div class="uk-margin-auto-vertical uk-align-center">
      <range-filter class="uk-margin-auto-vertical uk-align-center"></range-filter>
      <dropdown-filter api="Store_Franchise" emit-name="storeName" place-holder="Select a Store..."></dropdown-filter>
      <dropdown-filter api="Week" emit-name="week" place-holder="Select a Week..."></dropdown-filter>
      <dropdown-filter class="bottom-select" api="Period" emit-name="period" place-holder="Select a Period..."></dropdown-filter>

      <div class="form">
        <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">Metric</div>
        <div class="dx-field-value">
          <DxRadioGroup
              :items="metricTypes"
              :value="metricTypes[0]"
              layout="horizontal"
              @valueChanged="changedMetricType"
          />
        </div>
      </div>
        </div>
      </div>

    </div>
    <div class="button-css">
      <button class="uk-button uk-button-default button-exact" @click="sendFilter">Apply Filters</button>
      <button class="uk-button uk-button-default button-exact" @click="clearFilter">Clear Filters</button>
    </div>
  </div>
</template>

<script>
import RangeFilter from "./Filters/RangeFilter";
import DropdownFilter from "./Filters/DropdownFilter";
import {DxRadioGroup} from "devextreme-vue";

const filterObject = {
  startDate: "",
  endDate: "",
  isByWeek: false,
  storeName: [],
  groupName: [],
  storeType: [],
  period: [],
  week: [],
  corpOrFranchise: [],
  metricType: "NetSales"
};

export default {
  name: "FilterLoader",
  components: {
    RangeFilter,
    DropdownFilter,
    DxRadioGroup
  },

  data() {
    return {
      filterObject,
      firstRun: true,
      
      metricTypes: [
        {
          text: 'Net Sales',
          value: 'NetSales'
        },
        {
          text: 'Guest Count',
          value: 'GuestCount'
        }
      ]
    }
  },

  created() {
    this.eventHub.$on('datesArr', this.dates)
    this.eventHub.$on('storeName', this.storeName)
    this.eventHub.$on('groupName', this.groupName)
    this.eventHub.$on('storeType', this.storeType)
    this.eventHub.$on('period', this.period)
    this.eventHub.$on('week', this.week)
    this.eventHub.$on('corpOrFranchise', this.corpOrFranchise)
    this.eventHub.$on('isByWeek', this.isByWeek)
  },

  beforeDestroy() {
    this.eventHub.$off('datesArr');
    this.eventHub.$off('storeName');
    this.eventHub.$off('groupName');
    this.eventHub.$off('storeType');
    this.eventHub.$off('period');
    this.eventHub.$off('weekNumber');
    this.eventHub.$off('corpOrFranchise');
    this.eventHub.$off('isByWeek');
  },

  methods: {
    sendFilter: function () {
      //Goes to level 2 overall sales drill down  
      this.eventHub.$emit('rawFilterObject', filterObject);

      this.eventHub.$emit('metricType', filterObject.metricType);

      let parameters = {
        startDate: new Date(filterObject.startDate).toISOString(),
        endDate: new Date(filterObject.endDate).toISOString(),
        isByWeek: filterObject.isByWeek,
        metricType: filterObject.metricType
      }

      if (filterObject.storeName.length !== 0) {
        for (let x = 0; x < filterObject.storeName.length; x++)
          parameters["storeName[" + x + "]"] = filterObject.storeName[x];
      }

      if (filterObject.groupName.length !== 0) {
        for (let x = 0; x < filterObject.groupName.length; x++)
          if (filterObject.groupName[x] === "Slim's Chicken Coop") {
            parameters ["groupName[" + x + "]"] = "Slim''s Chicken Coop";
          } else {
            parameters ["groupName[" + x + "]"] = filterObject.groupName[x];
          }
      }

      if (filterObject.storeType.length !== 0) {
        for (let x = 0; x < filterObject.storeType.length; x++)
          parameters ["storeType[" + x + "]"] = filterObject.storeType[x];
      }

      if (filterObject.week.length !== 0) {
        for (let x = 0; x < filterObject.week.length; x++)
          parameters ["weekNumber[" + x + "]"] = filterObject.week[x];
      }

      if (filterObject.period.length !== 0) {
        for (let x = 0; x < filterObject.period.length; x++)
          parameters ["period[" + x + "]"] = filterObject.period[x];
      }

      if (filterObject.corpOrFranchise.length !== 0) {
        for (let x = 0; x < filterObject.corpOrFranchise.length; x++)
          parameters ["corpOrFranchise[" + x + "]"] = filterObject.corpOrFranchise[x];
      }


      let deepClone = JSON.parse(JSON.stringify(parameters));
      let deepClone2 = JSON.parse(JSON.stringify(parameters));

      let paramCorporate = deepClone;
      let paramFranchise = deepClone2;

      paramFranchise ["corpOrFranchise[0]"] = "Franchise";
      paramCorporate ["corpOrFranchise[0]"] = "Corporate";

      this.eventHub.$emit('filter', parameters);

      this.eventHub.$emit('filterDetailKpi', paramCorporate, paramFranchise, parameters);
    },

    clearFilter: function () {

      this.filterObject = {
        startDate: "",
        endDate: "",
        storeName: [],
        groupName: [],
        storeType: [],
        period: [],
        week: [],
        corpOrFranchise: [],
      };

      this.eventHub.$emit('clearFilter');
    },


    //LoadFilters

    dates: function (value) {
      filterObject.startDate = value[0];
      filterObject.endDate = value[1];
      
      if (this.firstRun)
      {
        this.sendFilter();
        this.firstRun = false;
      }
    },

    storeName: function (value) {
      filterObject.storeName = value;
    },

    groupName: function (value) {
      filterObject.groupName = value;
    },

    storeType: function (value) {
      filterObject.storeType = value;
    },

    period: function (value) {
      filterObject.period = value;
    },

    week: function (value) {
      filterObject.week = value;
    },

    changedMetricType: function (value) {
      console.log(value.value.value);
      filterObject.metricType = value.value.value;
    },

    corpOrFranchise: function (value) {
      filterObject.corpOrFranchise = value;
    },

    isByWeek: function (value) {
      
      filterObject.isByWeek = value;

      this.eventHub.$emit('rawFilterObject', filterObject);
      
      let parameters = {
        startDate: new Date(filterObject.startDate).toISOString(),
        endDate: new Date(filterObject.endDate).toISOString(),
        isByWeek: filterObject.isByWeek
      }

      if (filterObject.storeName.length !== 0) {
        for (let x = 0; x < filterObject.storeName.length; x++)
          parameters["storeName[" + x + "]"] = filterObject.storeName[x];
      }

      if (filterObject.groupName.length !== 0) {
        for (let x = 0; x < filterObject.groupName.length; x++)
          if (filterObject.groupName[x] === "Slim's Chicken Coop") {
            parameters ["groupName[" + x + "]"] = "Slim''s Chicken Coop";
          } else {
            parameters ["groupName[" + x + "]"] = filterObject.groupName[x];
          }
      }

      if (filterObject.storeType.length !== 0) {
        for (let x = 0; x < filterObject.storeType.length; x++)
          parameters ["storeType[" + x + "]"] = filterObject.storeType[x];
      }

      if (filterObject.week.length !== 0) {
        for (let x = 0; x < filterObject.week.length; x++)
          parameters ["weekNumber[" + x + "]"] = filterObject.week[x];
      }

      if (filterObject.period.length !== 0) {
        for (let x = 0; x < filterObject.period.length; x++)
          parameters ["period[" + x + "]"] = filterObject.period[x];
      }

      if (filterObject.corpOrFranchise.length !== 0) {
        for (let x = 0; x < filterObject.corpOrFranchise.length; x++)
          parameters ["corpOrFranchise[" + x + "]"] = filterObject.corpOrFranchise[x];
      }
      
      this.eventHub.$emit('aggregationChanged', parameters);
    }

  }

}
</script>

<style scoped>
.bottom-select {
  padding-bottom: 10px;
}

.center {
  display: inline-flex;
  justify-content: space-around;
}

.top-div {
  display: inline-block;
  margin-left: 60px;
}

.left-border {
  border-left: solid 1px #e4e4e4;
}

.select-box {
  margin: 15px;
}

.button-exact {
  margin-top: 12px;
  margin-left: 40px;
}

.button-css {
  border-top: solid 1px #e4e4e4;;
  text-align: center;
}
</style>
<template>
  <div>
    <div class="uk-margin-auto-vertical uk-align-center">
      <time-period-filter></time-period-filter>
      <dropdown-filter api="Store_Franchise" emit-name="channel_storeName" place-holder="Select a Store..."></dropdown-filter>
      <dropdown-filter api="Channel_Franchise" emit-name="channel_channel" place-holder="Select a Channel..."></dropdown-filter>
    </div>
    
    <div class="button-css">
      <button class="uk-button uk-button-default button-exact" @click="sendFilter">Apply Filters</button>
      <button class="uk-button uk-button-default button-exact" @click="clearFilter">Clear Filters</button>
    </div>
  </div>
</template>

<script>
import DropdownFilter from "../../OverallSalesDetails/Components/Filters/DropdownFilter";
import TimePeriodFilter from "./TimePeriodFilter";

const filterObject = {
  timePeriod: '',
  storeName: [],
  groupName: [],
  channel: [],
  corpOrFranchise: [],
};

export default {
  name: "FilterLoader",
  components: {
    DropdownFilter,
    TimePeriodFilter
  },

  data() {
    return {
      filterObject
    }
  },

  created() {
    this.eventHub.$on('channel_storeName', this.storeName)
    this.eventHub.$on('channel_channel', this.channel)
    this.eventHub.$on('channel_groupName', this.groupName)
    this.eventHub.$on('channel_corpOrFranchise', this.corpOrFranchise)
    this.eventHub.$on('channel_timeperiod', this.timePeriod)
  },

  beforeDestroy() {
    this.eventHub.$off('channel_storeName');
    this.eventHub.$off('channel_channel');
    this.eventHub.$off('channel_groupName');
    this.eventHub.$off('channel_corpOrFranchise');
    this.eventHub.$off('channel_timeperiod');
  },

  methods: {
    sendFilter: function () {
      //Goes to level 2 overall sales drill down
      let parameters = {
        timePeriod: filterObject.timePeriod
      }

      if (filterObject.storeName.length !== 0) {
        for (let x = 0; x < filterObject.storeName.length; x++)
          parameters["storeName[" + x + "]"] = filterObject.storeName[x];
      }

      if (filterObject.channel.length !== 0) {
        for (let x = 0; x < filterObject.channel.length; x++)
          parameters["channel[" + x + "]"] = filterObject.channel[x];
      }

      if (filterObject.groupName.length !== 0) {
        for (let x = 0; x < filterObject.groupName.length; x++)
          if (filterObject.groupName[x] === "Slim's Chicken Coop") {
            parameters ["groupName[" + x + "]"] = "Slim''s Chicken Coop";
          } else {
            parameters ["groupName[" + x + "]"] = filterObject.groupName[x];
          }
      }

      if (filterObject.corpOrFranchise.length !== 0) {
        for (let x = 0; x < filterObject.corpOrFranchise.length; x++)
          parameters ["corpOrFranchise[" + x + "]"] = filterObject.corpOrFranchise[x];
      }

      this.eventHub.$emit('channel_filter', parameters);
    },

    clearFilter: function () {

      this.filterObject = {
        timePeriod: '',
        storeName: [],
        groupName: [],
        channel: [],
        corpOrFranchise: [],
      };

      this.eventHub.$emit('clearFilter');
    },

    storeName: function (value) {
      filterObject.storeName = value;
    },

    channel: function (value) {
      filterObject.channel = value;
    },

    groupName: function (value) {
      filterObject.groupName = value;
    },

    corpOrFranchise: function (value) {
      filterObject.corpOrFranchise = value;
    },

    timePeriod: function (value) {
      filterObject.timePeriod = value;
    },
  }

}
</script>

<style scoped>
.bottom-select {
  padding-bottom: 10px;
}

.center {
  display: inline-flex;
  justify-content: space-around;
}

.top-div {
  display: inline-block;
  margin-left: 60px;
}

.left-border {
  border-left: solid 1px #e4e4e4;
}

.select-box {
  margin: 15px;
}

.button-exact {
  margin-top: 12px;
  margin-left: 40px;
}

.button-css {
  border-top: solid 1px #e4e4e4;;
  text-align: center;
}
</style>
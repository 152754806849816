import { render, staticRenderFns } from "./DropdownFilter.vue?vue&type=template&id=01fd486d&scoped=true"
import script from "./DropdownFilter.vue?vue&type=script&lang=js"
export * from "./DropdownFilter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01fd486d",
  null
  
)

export default component.exports
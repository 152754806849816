<template>
  <div v-if="dataSource !== null">
    <DxDataGrid
        :show-borders="true"
        :data-source="dataSource"
        :column-auto-width="true"
        @exporting="onExporting">

      <DxFilterRow :visible="true"/>

      <DxHeaderFilter :visible="true"/>

      <DxScrolling
         
          mode="infinite"/>


      <DxExport
          :enabled="true"
      />

      <DxColumn
          data-field="groupName"
          caption="Store Name"/>

      <DxColumn data-field="total" caption="Total" :format="getFormat"/>
      <DxColumn data-field="average" caption="Average" :format="getFormat"/>

      <DxColumn v-for="column in columnData" :data-field="column.dataField" :caption="colName(column.caption)" :format="getFormat"/>


    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxColumn,
  DxDataGrid,
  DxMasterDetail,
  DxFilterRow,
  DxHeaderFilter,
  DxScrolling,
  DxExport
} from 'devextreme-vue/data-grid';

import {createStore} from "devextreme-aspnet-data-nojquery";
import axios from "axios";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import numeral from "numeral";

export default {
  name: "OverallSalesByWeek",
  components: {
    DxDataGrid,
    DxColumn,
    DxMasterDetail,
    DxFilterRow,
    DxHeaderFilter,
    DxScrolling,
    DxExport
  },

  data() {
    return {
      dataSource: this.loadData(this.templateData.data.groupName),
      isLoading: false,
      columnData: null,
      promoWeeks: [],
      format: {style: 'currency', currency: 'USD', maximumFractionDigits: 2}
    }
  },


  props: {
    weeksRange: Array,
    templateData: {
      type: Object,
      default: () => {
      }
    },
    filterData: {
      type: Object,
      default: () => {
      }
    },
    
    metricType: String
  },

  filters: {
    formatPercentage(number) {
      if (number === null)
        return 'Null';

      number = number.toString() + "%";

      return number;
    },
  },

  methods: {

    colName(columnName) {
      if (columnName.split(' ')[0] !== 'Week')
        return columnName.split(' ')[0];
      else
        return columnName;
    },

    getFormat(num) {
      if (this.metricType === 'NetSales') {
        return numeral(num).format("$0,0");
      }
      else
        return numeral(num).format("0,0");
    },

    onExporting(e) {
      let dollarUS = Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0});
      let groupName = this.templateData.data.groupName;
      
      let title = groupName + ' Stores Overall Sales Table.xlsx'
      
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Overall Sales Table');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet
      }).then(function (dataGridRange) {
        for (let x = 2; x < dataGridRange.to.column + 1; x++) {
          for (let y = 2; y < dataGridRange.to.row + 1; y++) {

            let cell = worksheet.getRow(y).getCell(x)

            let cell2 = cell.value

            let cellValue = Number(cell2).toFixed(0).toString();

            let finalCell = dollarUS.format(cellValue)

            Object.assign(
                worksheet.getRow(y).getCell(x),
                {value: finalCell}
            );
          }
        }
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], {type: "application/octet-stream"}),
              title);
        });

      });
    },

    colShow(index) {
      return this.weeksRange.includes(index);
    },

    loadParameters(group) {
      let parameters = {
        startDate: this.filterData.startDate,
        endDate: this.filterData.endDate,
        isByWeek: this.filterData.isByWeek,
        metricType: this.metricType
      }
      
      if (this.filterData.storeName.length !== 0 || true) {
        for (let x = 0; x < this.filterData.storeName.length; x++)
          parameters["storeName[" + x + "]"] = this.filterData.storeName[x];
      }

      parameters ["groupName[0]"] = group;

      if (this.filterData.storeType.length !== 0) {
        for (let x = 0; x < this.filterData.storeType.length; x++)
          parameters ["storeType[" + x + "]"] = this.filterData.storeType[x];
      }

      if (this.filterData.week.length !== 0) {
        for (let x = 0; x < this.filterData.week.length; x++)
          parameters ["weekNumber[" + x + "]"] = this.filterData.week[x];
      }

      if (this.filterData.period.length !== 0) {
        for (let x = 0; x < this.filterData.period.length; x++)
          parameters ["period[" + x + "]"] = this.filterData.period[x];
      }

      if (this.filterData.corpOrFranchise.length !== 0) {
        for (let x = 0; x < this.filterData.corpOrFranchise.length; x++)
          parameters ["corpOrFranchise[" + x + "]"] = this.filterData.corpOrFranchise[x];
      }

      return parameters;

    },

    loadData(group) {
      let parameters = this.loadParameters(group);
      
      let me = this;
      const token = me.getTokenSilently();
      const url = this.getApiUrl();
      parameters.clientId = this.getClientId();
      
      axios.get(`${url}/api/slims-report/get-column-data`, {
        headers: {Authorization: `Bearer ${token}`},
        params: parameters
      }).then(response => {
        me.columnData = response.data;
        
      }).finally(function () {
      
      });

      return me.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/franchise/overall-sales-data-grid-nested`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = me.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }

          ajaxOptions.data = parameters;
        }
      });
    
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="zoom-out-div">
    <h4 class="uk-position-top-right text-dont-click fran-name">Franchise: {{franchiseName}}</h4>
    <div class="uk-container uk-container-expand uk-margin-small-bottom nav-bar-css">
      <ul class="uk-subnav uk-subnav-pill nav-bar-css nav-bar-text-css">
        <li @click="toggleOverviewPage" :class="{'uk-active': showOverviewCards}"><a href="#" class="nav-bar-text-css">Overview</a></li>
        <li :class="{'uk-active': showYesterdayCompDetail || showRolling7CompDetail || showPeriodCompDetail || showYtdCompDetail || showCompCalcApp}">
          <a href="#" class="nav-bar-text-css">Comp Performance <span data-uk-icon="icon: triangle-down"></span></a>
          <div data-uk-dropdown="mode: hover; delay-hide: 20">
            <ul class="uk-nav uk-dropdown-nav">
              <li class="uk-nav-header">{{franchiseName}}</li>
              <li @click="compDetailPage('yesterday')"><a href="#">Yesterday</a></li>
              <li @click="compDetailPage('rolling7')"><a href="#">Rolling 7 Day</a></li>
              <li @click="compDetailPage('period')"><a href="#">Period</a></li>
              <li @click="compDetailPage('ytd')"><a href="#">YTD</a></li>
              <li class="uk-nav-divider"></li>
              <li @click="toggleShowCompCalc()"><a href="#">Contribution To Comp</a></li>
            </ul>
          </div>
        </li>
        <li @click="toggleShowChannel" :class="{'uk-active': showChannelDetail}"><a href="#" class="nav-bar-text-css">Channel Detail</a></li>
        <li @click="toggleOverallSalesDetail" :class="{'uk-active': showOverallSalesDetail}"><a href="#" class="nav-bar-text-css">Overall Sales Performance</a></li>
      </ul>
      
    </div>

    <div v-show="showOverviewCards" class="iframe-border">
      <div class="uk-grid-medium" data-uk-grid data-uk-height-match>

        <div class="uk-width-1-2@xl">
          <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
            <div class="uk-card-header">
              <div class="uk-grid uk-grid-small">
                <div v-show="showCompSalesCard" class="uk-width-auto"><h4>Comp Sales Performance</h4></div>
                <div v-show="!showCompSalesCard" class="uk-width-auto"><h4>Comp Traffic Performance</h4></div>
                <div class="uk-width-expand uk-text-right panel-icons">
                  <button class="uk-button uk-button-primary uk-text-middle comp-button">
                    <span v-show="showCompSalesCard" @click="switchCompCard('Traffic')" class="comp-button-text">Show Traffic</span>
                    <span v-show="!showCompSalesCard" @click="switchCompCard('Sales')" class="comp-button-text">Show Sales</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="uk-card-body">
              <div class="chart-container">
                <comp-sales-card v-show="showCompSalesCard" :yesterday-dates="yesterdayDates" :rolling7-dates="rolling7Dates" :period-dates="periodDates" :ytd-dates="ytdDates"></comp-sales-card>
                <comp-traffic-card v-show="!showCompSalesCard" :yesterday-dates="yesterdayDates" :rolling7-dates="rolling7Dates" :period-dates="periodDates" :ytd-dates="ytdDates"></comp-traffic-card>
              </div>
            </div>
          </div>
        </div>

        <div class="uk-width-1-2@xl">
          <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
            <div class="uk-card-header">
              <div class="uk-grid uk-grid-small">
                <div class="uk-width-auto"><h4>Overall Sales Performance</h4></div>
                <div class="uk-width-expand uk-text-right panel-icons">
                  <button data-uk-tooltip="title: View Overall Sales Detail"  @click="toggleOverallSalesDetail" class="uk-button uk-button-primary uk-text-middle comp-button">
                    <span>Sales Detail</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="uk-card-body">
              <div class="chart-container">
                <overall-sales-card :yesterday-dates="yesterdayDates" :rolling7-dates="rolling7Dates" :period-dates="periodDates" :ytd-dates="ytdDates"></overall-sales-card>
              </div>
            </div>
          </div>
        </div>

        <div class="uk-width-1-1">
          <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
            <div class="uk-card-header">
              <div class="uk-grid uk-grid-small">
                <div class="uk-width-auto"><h4>Comp Sales Channel Summary</h4></div>
                <div class="uk-width-expand uk-text-right panel-icons">
                  <button data-uk-tooltip="title: View Channel Detail" @click="toggleShowChannel" class="uk-button uk-button-primary uk-text-middle comp-button">
                    <span>Channel Sales Detail</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="uk-card-body">
              <div class="chart-container">
                <div class="channel-container">
                  <channel-sales-card :yesterday-dates="yesterdayDates" :rolling7-dates="rolling7Dates" :period-dates="periodDates" :ytd-dates="ytdDates"></channel-sales-card>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="uk-width-1-1@l">
          <div class="uk-card uk-card-default uk-card-small">
            <div class="uk-card-header">
              <div class="uk-grid uk-grid-small">
                <div class="uk-width-auto"><h4>Store Overview</h4></div>
                <div class="uk-width-expand uk-text-right panel-icons">
                  <button class="uk-button uk-button-primary uk-text-middle comp-button">
                    <span v-show="!showCompSalesOverview" @click="switchOverview('comp')" class="comp-button-text">Show Comp %</span>
                    <span v-show="showCompSalesOverview" @click="switchOverview('sales')"
                          class="comp-button-text">Show $</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="uk-card-body">
              <div class="chart-container">
                <store-overview-grid v-if="!showCompSalesOverview"></store-overview-grid>
                <store-overview-grid-comp v-if="showCompSalesOverview"></store-overview-grid-comp>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>

    <!------------------------------>
    <!--YESTERDAY COMP DETAIL PAGE-->
    <!------------------------------>
    <yesterday-app v-if="showYesterdayCompDetail" :is-corporate="showCorp" :yesterday-dates="yesterdayDates"></yesterday-app>


    <!------------------------------>
    <!--ROLLING 7 COMP DETAIL PAGE-->
    <!------------------------------>
    <rolling7-app v-if="showRolling7CompDetail" :is-corporate="showCorp" :rolling7-dates="rolling7Dates"></rolling7-app>


    <!------------------------------>
    <!---PERIOD COMP DETAIL PAGE---->
    <!------------------------------>
    <period-detail-app v-if="showPeriodCompDetail" :is-corporate="showCorp" :period-dates="periodDates" ></period-detail-app>

    <!------------------------------>
    <!------YTD COMP DETAIL PAGE---->
    <!------------------------------>
    <ytd-detail-app v-if="showYtdCompDetail" :is-corporate="showCorp" :ytd-dates="ytdDates"></ytd-detail-app>

    <comp-calc-app v-if="showCompCalcApp" :time-range="compCalcTimeRange"></comp-calc-app>

    <channel-detail-app v-if="showChannelDetail"></channel-detail-app>

    <overall-sales-detail-app v-if="showOverallSalesDetail" :yesterday-dates="yesterdayDates" :rolling7-dates="rolling7Dates" :period-dates="periodDates" :ytd-dates="ytdDates"></overall-sales-detail-app>
  </div>
</template>

<script>
import CompSalesCard from "./Components/Homepage/CompSalesCard";
import OverallSalesCard from "./Components/Homepage/OverallSalesCard";
import AddPromotionModal from "./Components/Homepage/Components/AddPromotionModal";
import {DxPopup, DxPosition, DxToolbarItem} from 'devextreme-vue/popup';
import PromotionsCard from "./Components/Homepage/PromotionsCard";
import ChannelSalesCard from "./Components/Homepage/ChannelSalesCard";
import WordCloud from "./Components/Homepage/WordCloud";
import axios from "axios";
import CompTrafficCard from "./Components/Homepage/CompTrafficCard";
import PreviousPromotionsCard from "./Components/Homepage/PreviousPromotionsCard";
import TopStoresCard from "./Components/Homepage/TopStoresCard";

import StoreOverviewGridComp from "./Components/Homepage/Components/StoreOverviewGridComp";
import StoreOverviewGrid from "./Components/Homepage/Components/StoreOverviewGrid";
import YesterdayApp from "./Components/DetailPages/Parts/Yesterday/YesterdayApp";
import Rolling7App from "./Components/DetailPages/Parts/Rolling7/Rolling7App";
import PeriodDetailApp from "./Components/DetailPages/Parts/Period/PeriodDetailApp";
import CompCalcApp from "./Components/DetailPages/Parts/ContributionToCompCalculator/CompCalcApp";
import YtdDetailApp from "./Components/DetailPages/Parts/Ytd/YtdDetailApp";
import ChannelDetailApp from "./Components/DetailPages/Parts/ChannelDetail/ChannelDetailApp";
import OverallSalesDetailApp from "./Components/DetailPages/Parts/OverallSalesDetails/OverallSalesDetailApp";
export default {
  name: 'App',
  components: {
    CompSalesCard,
    OverallSalesCard,
    AddPromotionModal,
    DxPopup,
    PromotionsCard,
    ChannelSalesCard,
    WordCloud,
    CompTrafficCard,
    PreviousPromotionsCard,
    TopStoresCard,
    StoreOverviewGrid,
    StoreOverviewGridComp,
    YesterdayApp,
    Rolling7App,
    PeriodDetailApp,
    YtdDetailApp,
    CompCalcApp,
    ChannelDetailApp,
    OverallSalesDetailApp
    
  },
  data() {
    return {
      showOverviewCards: true,
      showCompDetailPage: false,


      yesterdayDates: [],
      rolling7Dates: [],
      periodDates: [],
      ytdDates: [],
      franchiseName: '',

      //Might Use these to show different details but not sure 
      showYesterdayCompDetail: false,
      showRolling7CompDetail: false,
      showPeriodCompDetail: false,
      showYtdCompDetail: false,
      showChannelDetail: false,
      showCompCalcApp: false,
      showStoreOverview: false,
      noPromotions: false,
      showCompSalesOverview: false,
      
      showOverallSalesDetail: false,

      compCalcTimeRange: 'YTD',

      showCompSalesCard: true,
      showPromoDetail2: false,

      promotionsToolTip: "Show Previous Promotions",
      showCurrentPromotions: true,
      showCorp: false,
      popupVisible: false,

      promoObject: null,
      promoObject2: null,
      showPromoDetail: false,

      savedPromotions: null,
      savedPromotionsNotActive: null
    };
  },

  mounted () {

    this.loadFranchiseName();
    
    let me = this;
    const token = this.getTokenSilently();
    const url = this.getApiUrl();
    const clientId = this.getClientId();

    axios.get(`${url}/api/slims-report/kpi-dates`, {
      headers: {Authorization: `Bearer ${token}`},
      params: {
        clientId: clientId,
      }
    })
        .then(response => {

          me.yesterdayDates.push(new Date(response.data[0].startDate).toLocaleDateString("en-US"));

          me.rolling7Dates.push(new Date (response.data[1].startDate).toLocaleDateString("en-US"));
          me.rolling7Dates.push(new Date (response.data[1].endDate).toLocaleDateString("en-US"));

          me.periodDates.push(new Date (response.data[2].startDate).toLocaleDateString("en-US"));
          me.periodDates.push(new Date (response.data[2].endDate).toLocaleDateString("en-US"));

          me.ytdDates.push(new Date (response.data[3].startDate).toLocaleDateString("en-US"));
          me.ytdDates.push(new Date (response.data[3].endDate).toLocaleDateString("en-US"));

        })
        .catch(err => {})
        .finally(() => {
        });
  },

  created() {
    this.eventHub.$on('compDetailPage', this.compDetailPage);
    this.eventHub.$on('backToOverview', this.toggleOverviewPage);
    this.eventHub.$on('showPromoDetail', this.togglePromoDetail);
    this.eventHub.$on('navbarPromotions', this.savePromotionNames);
    this.eventHub.$on('compCalc', this.toggleShowCompCalc);
    this.eventHub.$on('navbarPromotionsNotActive', this.saveNotActivePromotions);

  },

  beforeDestroy() {
    this.eventHub.$off('compDetailPage');
    this.eventHub.$off('backToOverview');
    this.eventHub.$off('showPromoDetail');
    this.eventHub.$off('navBarPromotions');
    this.eventHub.$off('compCalc');
  },



  methods: {
    toggleEverythingOff () {
      //All pages
      this.showYesterdayCompDetail = false;
      this.showRolling7CompDetail = false;
      this.showPeriodCompDetail = false;
      this.showYtdCompDetail = false;
      this.showPromoDetail = false;
      this.showPromoDetail2 = false;
      this.showOverallSalesDetail = false;
      this.showChannelDetail = false;
      this.showCompCalcApp = false;
      this.showStoreOverview = false;
    },

    toggleShowStoreOverview () {
      this.toggleEverythingOff();
      this.showOverviewCards = false;
      this.showStoreOverview = true;
    },

    toggleEverythingOffForPromo () {
      //All pages
      this.showYesterdayCompDetail = false;
      this.showRolling7CompDetail = false;
      this.showPeriodCompDetail = false;
      this.showYtdCompDetail = false;
      this.showOverallSalesDetail = false;
      this.showChannelDetail = false;
      this.showCompCalcApp = false;
      this.showStoreOverview = false;
    },

    switchOverview(value) {
      if (value === 'sales')
        this.showCompSalesOverview = false;
      if (value === 'comp')
        this.showCompSalesOverview = true;
    },
  
    toggleShowCompCalc (view) {
      this.toggleEverythingOff();

      if (view !== undefined)
        this.compCalcTimeRange = view;

      this.showOverviewCards = false;
      this.showCompCalcApp = true;
    },

    switchCompCard(card) {
      if (card === 'Sales') {
        this.showCompSalesCard = true;
      }
      if (card === 'Traffic') {
        this.showCompSalesCard = false;
      }
    },


    toggleOverviewPage() {
      //Overview Page

      this.toggleEverythingOff();

      this.showOverviewCards = true;

    },

    toggleShowChannel() {
      this.toggleEverythingOff();

      this.showOverviewCards = false;
      this.showChannelDetail = true;
    },

    toggleShowCurrentPromotions() {
      this.showCurrentPromotions = !this.showCurrentPromotions;

      if (this.showCurrentPromotions === true) {
        this.promotionsToolTip = "Show Previous Promotions";
      }
      else {
        this.promotionsToolTip = "Show Current Promotions";
      }
    },

    toggleOverallSalesDetail() {
      this.toggleEverythingOff();
      this.showOverviewCards = false;
      this.showOverallSalesDetail = true;
    },

    togglePromoDetail(promoObject) {
      this.toggleEverythingOffForPromo();
      let promo1 = this.showPromoDetail;
      let promo2 = this.showPromoDetail2;

      if (promo1 === false && promo2 === false) {
        this.showPromoDetail2 = false;
        this.showPromoDetail = true;
        this.promoObject = promoObject;
        this.promoObject2 = null;
      }

      if (promo1 === true && promo2 === false) {
        this.showPromoDetail2 = true;
        this.showPromoDetail = false;
        this.promoObject = null;
        this.promoObject2 = promoObject;
      }

      if (promo1 === false && promo2 === true) {
        this.showPromoDetail2 = false;
        this.showPromoDetail = true;
        this.promoObject = promoObject;
        this.promoObject2 = null;
      }

      this.showOverviewCards = false;
    },

    togglePromotionsModal() {
      this.popupVisible = !this.popupVisible;
    },

    savePromotionNames(promotions) {

      if (promotions.length <= 0 ) {
        this.noPromotions = true;
      }
      this.savedPromotions = promotions;
    },

    saveNotActivePromotions(promotions) {
      this.savedPromotionsNotActive = promotions;
    },
    
    loadFranchiseName() {
      let me = this
      this.isLoading = true;
      const token = this.getTokenSilently();
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      axios.get(`${url}/api/slims-report/franchise/franchise-name`, {
        headers: {Authorization: `Bearer ${token}`},
        params: {
          clientId: clientId,
        }
      })
          .then(response => {
            me.franchiseName = response.data[0].groupName;
          })
          .catch(err => {
          })
          .finally(() => {
            me.isLoading = false;
          });
    },

    compDetailPage(view) {

      if (!this.showOverviewCards) {
        this.toggleEverythingOff();
      }

      this.showOverviewCards = false;
      // this.showCompDetailPage = true;

      if (view === 'yesterday') {
        this.showYesterdayCompDetail = true;
        this.showCorp = false;
      }
      if (view === 'rolling7') {
        this.showRolling7CompDetail = true;
        this.showCorp = false;
      }
      if (view === 'period') {
        this.showPeriodCompDetail = true;
        this.showCorp = false;
      }
      if (view === 'ytd') {
        this.showYtdCompDetail = true;
        this.showCorp = false;
      }


      if (view === 'yesterday_corp') {
        this.showYesterdayCompDetail = true;
        this.showCorp = true;
      }
      if (view === 'rolling7_corp') {
        this.showRolling7CompDetail = true;
        this.showCorp = true;
      }
      if (view === 'period_corp') {
        this.showPeriodCompDetail = true;
        this.showCorp = true;
      }
      if (view === 'ytd_corp') {
        this.showYtdCompDetail = true;
        this.showCorp = true;
      }
    }
  },

}
</script>

<style lang="scss">
.color-card {
  background-color: #f7f7f7;
}

.clickable:hover {
  cursor: pointer;
}

.promotion-button-css {
  position: absolute;
  right: 10px;
  top: 15px;
}

.promotion-title {
  top: 20px;
  position: absolute;
  left: 20px;
}

.header-height {
  height: 30px
}

.comp-button {
  height: 30px;
  display: inline-flex !important;
  align-items: center;
}

.promo-container {
  height: 310px;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.promo-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.channel-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.iframe-border {
  padding: 3px;
  margin-bottom: 30px;
}

.nav-bar-css {
  margin-right: 10%;
  width: 90vw;
}

.nav-bar-text-css {
  font-size: 1.2vw;
}

.text-dont-click:hover {
  cursor: default
}

.channel-container {
  height: 275px;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.fran-name {
  margin-right: 30px;
}

.fran-name:hover {
  
  cursor: default
}
</style>
<template>
  <div v-if="columnData !== null">
    <div v-if="columnData.length <= 0 && !isLoading" class="uk-text-bold uk-padding">No Data Available With Selected Filters
    </div>
    <div v-if="columnData !== null && columnData.length > 0 && dataSource !== null">
      <DxDataGrid
          :show-borders="true"
          :data-source="dataSource"
          :column-auto-width="true"
          @exporting="onExporting"
      >
        <DxMasterDetail
            :enabled="true"
            template="master-detail"
        />

        <DxExport
            :enabled="true"
        />

        <DxFilterRow :visible="true"/>

        <DxHeaderFilter :visible="true"/>

        <DxScrolling
            column-rendering-mode="virtual"
            mode="infinite"/>

        <DxColumn
            data-field="groupName"
            caption="Group Name"/>

        <DxColumn data-field="total" caption="Total" :format="getFormat"/>
        <DxColumn data-field="average" caption="Average" :format="getFormat"/>

        <DxColumn v-for="column in columnData" :data-field="column.dataField" :caption="colName(column.caption)" :format="getFormat"/>
        
        <template #master-detail="{ data }">
          <div class="div-background">
            <overall-sales-by-week-nested :template-data="data" :metric-type="savedMetricType" :filter-data="savedFilters" :weeks-range="promoWeeks"/>
          </div>
        </template>

      </DxDataGrid>
    </div>
  </div>
</template>

<script>
import {
  DxColumn,
  DxDataGrid,
  DxMasterDetail,
  DxFilterRow,
  DxHeaderFilter,
  DxScrolling,
  DxExport
} from 'devextreme-vue/data-grid';

import {createStore} from "devextreme-aspnet-data-nojquery";
import axios from "axios";
import OverallSalesByWeekNested from "./OverallSalesByWeekNested";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import numeral from "numeral";

export default {
  name: "OverallSalesByWeek",
  components: {
    DxDataGrid,
    DxColumn,
    DxMasterDetail,
    DxFilterRow,
    DxHeaderFilter,
    DxScrolling,
    OverallSalesByWeekNested,
    DxExport
  },

  data() {
    return {
      savedMetricType: "NetSales",
      dataSource: null,
      isLoading: false,
      columnData: null,
      promoWeeks: [],
      showNoData: false,
      savedFilters: {
        startDate: "",
        endDate: "",
        storeName: [],
        groupName: [],
        storeType: [],
        period: [],
        week: [],
        corpOrFranchise: [],
      },

      dollarFormat: {style: 'currency', currency: 'USD', maximumFractionDigits: 0},

      guestCountFormat: {style: 'decimal', maximumFractionDigits: 0}
    }
  },

  mounted() {
    // this.loadData();
  },


  created() {
    this.eventHub.$on('filter', this.loadDataFilters)
    this.eventHub.$on('clearFilter', this.loadData)
    this.eventHub.$on('rawFilterObject', this.saveRawFilters)
    this.eventHub.$on('aggregationChanged', this.loadDataFilters)
    this.eventHub.$on('metricType', this.metricType)
  },

  beforeDestroy() {
    this.eventHub.$off('filter');
    this.eventHub.$off('clearFilter');
    this.eventHub.$off('rawFilterObject');
    this.eventHub.$off('aggregationChanged')
    this.eventHub.$off('metricType')
  },


  props: {
    promotionData: {
      type: Object,
      default: () => {
      }
    }
  },

  filters: {
    formatPercentage(number) {
      if (number === null)
        return 'Null';

      number = number.toString() + "%";

      return number;
    },


    formatMoney(number) {
      if (number == null)
        return 'Unknown';
      return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}).format(number);
    }
  },

  methods: {
    
    colName(columnName) {
      if (columnName.split(' ')[0] !== 'Week')
        return columnName.split(' ')[0];
      else
        return columnName;
    },
    
    metricType (value) {
      this.savedMetricType = value;
    },
    
    getFormat(num)
    {
      if (this.savedMetricType === 'NetSales') {
        return numeral(num).format("$0,0");
      }
      else
        return numeral(num).format("0,0");
    },    
    
    saveRawFilters(rawFilters) {
      this.savedFilters = rawFilters;
    },

    colShow(index) {
      return this.promoWeeks.includes(index);
    },

    onExporting(e) {

      let dollarUS = Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0});

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Overall Sales Table');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet
      }).then(function (dataGridRange) {
        for (let x = 2; x < dataGridRange.to.column + 1; x++) {
          for (let y = 2; y < dataGridRange.to.row + 1; y++) {

            let cell = worksheet.getRow(y).getCell(x);

            let cell2 = cell.value;

            let cellValue = Number(cell2).toFixed(0).toString();

            let finalCell = dollarUS.format(cellValue);
            
            Object.assign(
                worksheet.getRow(y).getCell(x),
                {value: finalCell}
            );
          }
        }
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], {type: "application/octet-stream"}),
              'Franchise Overall Sales Table.xlsx');
        });

      });
    },

    loadDataFilters(filterObject) {
      
      
      this.columnData = null;
      this.dataSource = null;
      this.isLoading = true;

      const token = this.getTokenSilently();
      let me = this;
      const url = this.getApiUrl();
      filterObject.clientId = this.getClientId();

      axios.get(`${url}/api/slims-report/get-column-data`, {
        headers: {Authorization: `Bearer ${token}`},
        params: filterObject
      }).then(response => {
        me.columnData = response.data;

      }).finally(function () {

        me.dataSource = createStore({
          loadUrl: `${url}/api/slims-report/franchise/overall-sales-data-grid`,
          onBeforeSend: (method, ajaxOptions) => {
            const token = me.getTokenSilently();
            ajaxOptions.headers = {
              Authorization: `Bearer ${token}`
            }

            ajaxOptions.data = filterObject
          }
        });

        me.isLoading = false;


      });
    },

    loadData() {

      this.isLoading = true;

      const token = this.getTokenSilently();
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      let me = this;
      axios.get(`${url}/api/slims-report/get-column-data`, {
        headers: {Authorization: `Bearer ${token}`},
        params: {
          clientId: clientId
        }
      }).then(response => {
        me.columnData = response.data;
      }).finally(function () {
        
        me.dataSource = createStore({
          loadUrl: `${url}/api/slims-report/franchise/overall-sales-data-grid`,
          onBeforeSend: (method, ajaxOptions) => {
            const token = me.getTokenSilently();
            ajaxOptions.headers = {
              Authorization: `Bearer ${token}`
            }
            ajaxOptions.data = {
              clientId: clientId
            }

          }
        });

        me.isLoading = false;

      });
    },
  }
}
</script>

<style scoped>
.div-background {
  border: solid 1px black;
}
</style>